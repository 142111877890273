<div>
  <Collapsible name="capacity-info" bind:open>
    <h3 slot="label" class="mb0">
      {#if showName}
        <div class="small">Opportunity overview</div>
        <div class="flex-row flex-align-center g05">
          <CapacityGroupSizeIcon {capacity} iconProps={{ sm: true }} />
          <span>{capacity.name}</span>
        </div>
      {:else}
        Opportunity overview
      {/if}
    </h3>

    <div class="capacity-overview-container">
      <div class="capacity-overview">
        <div class="general">
          <h4>General information</h4>
          <div class="flex-column g1">
            <div>
              <h5>Location</h5>
              <CapacityLocations {capacity} />
            </div>

            <div>
              <h5>Service</h5>
              {#if capacity.service}
                <IconTextLink
                  icon="shapes"
                  iconProps={{ class: 'color-text-purple' }}
                  text={capacity.service.name}
                  href={`/services/${capacity.serviceId}`}
                />
              {:else}
                <em class="small text-gray">None</em>
              {/if}
            </div>

            {#if capacity.rotationTypes.length}
              <div style={tagCloudStyle}>
                <h5>{pluralCount('Experience type', capacity.rotationTypes, 'omitNumber')}</h5>
                <TagCloud ctx={{ rotationTypes: capacity.rotationTypes }} hideTooltip />
              </div>
            {/if}

            {#if capacity.specialties.length || capacity.service?.specialties.length}
              {@const specialties = computeCapacitySpecialties(capacity)}
              <div style={tagCloudStyle}>
                <h5>{pluralCount('Specialty', specialties, 'omitNumber')}</h5>
                <TagCloud ctx={{ specialties }} hideTooltip />
              </div>
            {/if}
          </div>
        </div>

        <div class="visibility">
          <h4>Visibility & search settings</h4>
          <div>
            <CapacityOpenForSchedulingLabel {capacity} />
            <CapacityAllowCoordinatorSchedulingLabel {capacity} />
            <CapacityPostToSearchPageLabel {capacity} />
            <CapacityAllowStudentsToLeaveGroupMatchLabel {capacity} />
          </div>
        </div>

        <div class="new-requests">
          <h4>New request settings</h4>
          <div style="max-width: 470px">
            <CapacityNoNewRequestsWhenLabel {capacity} />
          </div>
        </div>

        <div class="schedule-info">
          <h4>Schedule information</h4>
          <div class="flex-column g1">
            <div>
              <h5>Opportunity dates</h5>
              <StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} />
            </div>
            <div>
              <h5>Lead time</h5>
              <CapacityLeadTimeLabel {capacity} verbose={false} />
            </div>

            <div>
              <h5>Detailed schedules</h5>
              <CapacityDetailedScheduleCanBeSetByLabel {capacity} />

              {#if capacity.detailedScheduleCanBeSetBy !== DetailedScheduleCanBeSetBy.NoOne}
                <CapacityRequestAvailabilityPolicyLabel {capacity} />
              {/if}
            </div>
          </div>
        </div>

        <div class="student-requirements">
          <h4>Student requirements</h4>
          <div class="flex-column g1">
            <div>
              <h5>
                {#if capacity.studentYears.length > 1}
                  Years of study
                {:else}
                  Year of study
                {/if}
              </h5>
              <CapacityStudentYearsLabel {capacity} />
            </div>

            <div>
              <h5>Disciplines</h5>
              <CapacityDisciplinesLabel {capacity} />
            </div>
          </div>
        </div>

        <div class="school-info">
          <h4>School information</h4>

          <div class="flex-column g1">
            <!-- TODO: Replace with <CapacityMatchesSummary>?? -->
            {#if isHealth}
              <div>
                <h5>Available rotations</h5>
                <div>
                  {#if capacity.maxMatches}
                    {totalCapacityMatchCount} / {capacity.maxMatches}
                    <CapacityGroupSizeIcon {capacity} />
                  {:else}
                    {totalCapacityMatchCount} /
                    <Icon name="infinity" lg class="text-info mr05" />
                    Unlimited
                  {/if}
                  {#if capacity.allowGroups}
                    group
                  {/if}
                  {pluralCount('rotation', capacity.maxMatches, 'omitNumber')}
                  available
                </div>
              </div>
            {/if}

            {#if filteredGuests?.length}
              {#if filteredGuests.length === 1}
                <Spotlight
                  enabled={spotlightEnabled}
                  spotlightClass="grid g1 p2 rounded-lg border-2 btn-pulsate-info bg-white"
                  spotlightStyle="grid-template-columns: auto auto auto; grid-template-rows: auto auto auto; grid-template-areas: 'header header header' 'before arrow after' 'footer footer footer'"
                >
                  <svelte:fragment slot="before">
                    <h3 class="m0 pb2" style="grid-area: header">
                      <Icon name="info" class="text-info" />
                      The rotations(s) will be associated with this school instead.
                    </h3>
                    <div style="grid-area: before">
                      <CapacityGuestCardDetailed {capacity} guest={capacityGuestBefore} />
                    </div>
                    <div class="p2" style="grid-area: arrow; align-self: center">
                      <Icon x3 name="arrow-right" />
                    </div>
                  </svelte:fragment>

                  <div style="grid-area: after">
                    <CapacityGuestCardDetailed {capacity} guest={capacityGuest ?? filteredGuests[0]} />
                  </div>

                  <div slot="after" class="pt2" style="grid-area: footer">
                    <Btn
                      class="btn btn-primary"
                      on:click={() => {
                        capacityGuestBefore = capacityGuest
                      }}>Got it</Btn
                    >
                  </div>
                </Spotlight>
              {:else}
                <div style="max-height: 300px; overflow-y:auto; overflow-x:hidden;">
                  {#each filteredGuests as guest}
                    <CapacityGuestCardLimited {capacity} {guest} />
                  {/each}
                </div>
              {/if}
            {/if}
          </div>
        </div>
      </div>
    </div>
  </Collapsible>
</div>

<div class="hide">
  <div bind:this={tooltipElem} class="flex-column g05 text-left py05">
    {#if capacity.disciplines.length}
      Inherited
    {:else}
      Subset inherited
    {/if}

    {#if capacity.service}
      from
      <span class="nowrap">
        <Icon name="shapes" class="color-text-purple" />
        <span>{capacity.service.name}</span>
      </span>
    {/if}

    {#if capacity.disciplines.length}
      {#each capacity.disciplines as discipline}
        <div><Icon name="book" class="color-text-teal" /> {discipline}</div>
      {/each}
    {:else}
      <div class="flex-row flex-align-center g05">
        <Icon name="book" class="color-text-teal" /><em>Any discipline</em>
      </div>
    {/if}
  </div>
</div>

<script>
  import { computeCapacitySpecialties } from 'components/InheritedTagPicker.svelte'
  import { DetailedScheduleCanBeSetBy, PersonaType } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityAllowCoordinatorSchedulingLabel from 'components/Capacity.AllowCoordinatorSchedulingLabel.svelte'
  import CapacityAllowStudentsToLeaveGroupMatchLabel from 'components/Capacity.AllowStudentsToLeaveGroupMatchLabel.svelte'
  import CapacityDetailedScheduleCanBeSetByLabel from 'components/Capacity.DetailedScheduleCanBeSetByLabel.svelte'
  import CapacityGroupSizeIcon from 'components/CapacityGroupSizeIcon.svelte'
  import CapacityGuestCardDetailed from './CapacityGuestCardDetailed.svelte'
  import CapacityGuestCardLimited from './CapacityGuestCard.Limited.svelte'
  import CapacityLeadTimeLabel from 'components/Capacity.LeadTimeLabel.svelte'
  import CapacityLocations from 'components/CapacityLocations.svelte'
  import CapacityNoNewRequestsWhenLabel from 'components/Capacity.NoNewRequestsWhenLabel.svelte'
  import CapacityOpenForSchedulingLabel from 'components/Capacity.OpenForSchedulingLabel.svelte'
  import CapacityPostToSearchPageLabel from 'components/Capacity.PostToSearchPageLabel.svelte'
  import CapacityRequestAvailabilityPolicyLabel from 'components/Capacity.RequestAvailabilityPolicyLabel.svelte'
  import CapacityStudentYearsLabel from 'components/Capacity.StudentYearsLabel.svelte'
  import CapacityDisciplinesLabel from 'components/Capacity.DisciplinesLabel.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import persona from 'stores/persona.js'
  import Spotlight from 'components/Spotlight.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import TagCloud from 'components/TagCloud.svelte'

  export let capacity
  export let showName = false
  export let capacityGuest = null
  export let capacityGuestBefore = null
  export let canSpotlightCapacityGuestChange = false

  const tagCloudStyle = 'max-width: 400px'

  let tooltipElem
  let open = true

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: totalCapacityMatchCount = capacity.guests?.[0]?.totalCapacityMatchCount ?? 0
  $: filteredGuests = capacityGuest ? [capacityGuest] : capacity.guests
  $: spotlightEnabled =
    canSpotlightCapacityGuestChange &&
    capacityGuestBefore &&
    capacityGuest &&
    (capacityGuestBefore.guestOrgId ?? null) !== (capacityGuest.guestOrgId ?? null)

  $: if (spotlightEnabled) open = true
</script>

<style>
  h3,
  h4,
  h5 {
    margin: 0 !important;
  }

  h5 {
    margin-bottom: 0.35em !important;
  }

  .capacity-overview-container {
    container: capacity-overview / inline-size;
    padding: 10px 30px;
  }

  .capacity-overview {
    display: grid;
    gap: 20px;
    grid-template-areas: 'general' 'visibility' 'new-requests' 'schedule-info' 'student-requirements' 'school-info';
  }

  .general {
    grid-area: general;
  }
  .visibility {
    grid-area: visibility;
  }
  .new-requests {
    grid-area: new-requests;
  }
  .schedule-info {
    grid-area: schedule-info;
  }
  .student-requirements {
    grid-area: student-requirements;
  }
  .school-info {
    grid-area: school-info;
  }

  @container capacity-overview (min-width: 880px) {
    .capacity-overview {
      grid-template-areas:
        'general schedule-info'
        'visibility school-info'
        'new-requests school-info'
        'student-requirements school-info';
      grid-template-rows: auto auto auto 1fr;
      grid-template-columns: auto 1fr;
    }
  }

  @container capacity-overview (min-width: 1250px) {
    .capacity-overview {
      grid-template-areas:
        'general schedule-info school-info'
        'visibility student-requirements school-info'
        'new-requests student-requirements school-info';
      grid-template-rows: auto auto 1fr;
      grid-template-columns: auto auto 1fr;
    }
  }

  @container capacity-overview (min-width: 1470px) {
    .capacity-overview {
      grid-template-areas:
        'general visibility schedule-info school-info'
        'general new-requests schedule-info school-info'
        'general student-requirements schedule-info school-info';
      grid-template-rows: auto auto 1fr;
      grid-template-columns: auto auto auto 1fr;
    }
  }
</style>
