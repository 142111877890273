<!-- same icon as schedule students page... kind of lame -->
{#if show}
  <div class="flex-row flex-align-center flex-wrap g05">
    {#if allowCoordinatorScheduling}
      <span>Allows coordinators to</span>
    {:else}
      <span>Does</span>
      <strong class="strongish">NOT</strong>
      <span>allow coordinators to</span>
    {/if}

    <span class="nowrap">
      <Icon name="plus" />
      <strong class="strongish">Schedule students</strong>
    </span>
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'

  export let capacity
  export let show = true

  $: allowCoordinatorScheduling = capacity.allowCoordinatorScheduling
</script>
