<div>
  Schools can schedule into
  <strong class="strongish">
    {#if capacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.MultipleAvailableShifts}
      multiple windows
    {:else}
      a single window
    {/if}
  </strong>
  that they have access to
</div>

<script>
  import { RequestAvailabilityPolicy } from 'config/enums.js'

  export let capacity
</script>
