<div>
  <span class="has-tooltip" use:tip={tooltipElem ? { content: tooltipElem, options: { interactive: true, theme: 'light-gray-scrollable' } } : null}>
    {#if capacity.disciplines.length}
      Custom disciplines set
    {:else}
      Inherit school disciplines
    {/if}
  </span>
  <div class="hide">
    <div bind:this={tooltipElem} class="flex-column g05 text-left py05">
      <em>
        {#if capacity.disciplines.length}
          Inherited from
        {:else}
          Subset inherited from
        {/if}

        {#if capacity.service}
          <Icon name="shapes" class="color-text-purple" />
          {capacity.service.name}
        {/if}
      </em>

      {#if capacity.disciplines.length}
        {#each capacity.disciplines as discipline}
          <div><Icon name="book" class="color-text-teal" /> {discipline}</div>
        {/each}
      {:else}
        <div class="flex-row flex-align-center g05">
          <Icon name="book" class="color-text-teal" /><em>Any discipline</em>
        </div>
      {/if}
    </div>
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let capacity

  let tooltipElem = null
</script>
