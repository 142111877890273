{#if capacity.postToSearchPage}
  {#if capacity.allowCoordinatorScheduling}
    <span class="text-primary">Students</span> and <span class="text-school">school coordinators</span>{postfix}
  {:else}
    <span class="text-primary">Students</span>{postfix}
  {/if}
{:else if capacity.allowCoordinatorScheduling}
  <span class="text-school">School coordinators</span>{postfix}
{:else}
  Requesters{postfix}
{/if}

<script>
  export let capacity
  export let postfix = ''
</script>
