{#if match?.matchId != null && controller != null}
  <div class="match-modal" use:focusTrap>
    <FormValidator bind:this={formValidator} bind:submitted>
      <MatchModalTitle {match} {close} {scrolledTop} {reloadMatchDetailsAndActivity} bind:titleEl />

      <div class="main" style={detailsHeightAndMargin} on:scroll={onMatchModalScroll}>
        <div class="details">
          <MatchModalProposedChanges {match} {onMatchChanged} />
          <MatchModalBasicInfo bind:match {onMatchChanged} />
          <MatchModalPeople bind:match bind:conflicts {students} {schoolStaff} {healthStaff} {matchInitial} />
          <MatchModalSchedule bind:match bind:conflicts {matchInitial} {refreshStepsDebounced} {isValidStartAndEndDates} {submitted} />
          {#if match.hourLoggingEnabled && match.status >= MatchStatus.Active && ($persona.isStudent || personaService.canUseAnyFeatureType(FeatureType.HourLogging))}
            <MatchModalHourLogging {match} {students} {onSaveExpectedHours} bind:matchChanged />
          {/if}
          <MatchModalLearningObjectives bind:match bind:conflicts {matchInitial} />
          <MatchModalSteps bind:match {matchInitial} {startDateOverride} {endDateOverride} {saveMatch} />
        </div>
        <MatchModalActivity bind:this={activityComponent} match={matchInitial} {onMatchChanged} style={$windowWidth > 1200 ? marginTop : null} />
      </div>

      <MatchModalActions
        bind:actionPanelEl
        bind:changeDueDate
        bind:changeComment
        {match}
        {matchInitial}
        {change}
        {hasChanges}
        {changesCanBeProposed}
        {changesCanBeSaved}
        {savingMatch}
        {formValidator}
        {saveMatch}
        {buildSaveMatchModel}
        {onMatchChanged}
        {close}
        disabled={matchChanging}
      />
    </FormValidator>
  </div>
{/if}

<script>
  import {
    changesWouldNotAffectOtherSide,
    getChangesViewModel,
    getDefaultChangeComment,
    mergeMatch,
    setMatchBasicInfo,
    setMatchStepInfo,
  } from 'services/match-merger.js'
  import { createEventDispatcher, onDestroy, tick } from 'svelte'
  import { FeatureType, MatchRole, MatchStatus } from 'config/enums.js'
  import { formatEnumValue } from 'services/formatters.js'
  import { lockScroll, unlockScroll } from 'services/scroll-service.js'
  import { toFriendlyList } from 'services/string-utils.js'
  import api from 'services/api.js'
  import confirms from 'stores/confirms.js'
  import focusTrap from 'decorators/focus-trap.js'
  import FormValidator from 'components/FormValidator.svelte'
  import getMatchController from 'services/match-controller.js'
  import MatchModalActions from 'components/MatchModal.Actions.svelte'
  import MatchModalActivity from 'components/MatchModal.Activity.svelte'
  import MatchModalBasicInfo from 'components/MatchModal.BasicInfo.svelte'
  import MatchModalHourLogging from 'components/MatchModal.HourLogging.svelte'
  import MatchModalLearningObjectives from 'components/MatchModal.LearningObjectives.svelte'
  import MatchModalPeople from 'components/MatchModal.People.svelte'
  import MatchModalProposedChanges from 'components/MatchModal.ProposedChanges.svelte'
  import MatchModalSchedule from 'components/MatchModal.Schedule.svelte'
  import MatchModalSteps from 'components/MatchModal.Steps.svelte'
  import MatchModalTitle from 'components/MatchModal.Title.svelte'
  import modelState from 'stores/model-state.js'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import sockets from 'services/sockets.js'
  import toaster from 'services/toaster.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'
  import windowWidth from 'stores/window-width.js'
  import stepActions from 'stores/step-submission-row-actions.js'

  export let matchId = null
  export let submitted = false

  const dispatch = createEventDispatcher()
  const form = 'MatchModal'

  // refresh steps when startdate/enddate changes
  // debounced so handles better when user types date manually and doesn't flood server with unnecessary requests
  // doing as event handler instead of observer so it doesn't fire when we're simply setting a new remote match in--it already has latest steps and other data
  const refreshStepsDebounced = _.debounce(() => refreshSteps(), 300)

  const loadedDueToRequestIds = []
  const argsByRequestId = {}
  let savingMatch = false
  let formValidator = null
  // start with an empty object to make null checks not necessary.
  let match = {}
  let matchInitial = {}
  let changeComment = ''
  let changeDueDate = null
  let conflicts = null
  let activityComponent
  let matchChanged = false
  let refreshingStepsTask
  let scrolledTop = true
  let titleEl = null
  let actionPanelEl = null
  let detailsHeightAndMargin = ''
  let marginTop = ''
  let reloadingDetailsTask = null
  let observer = null

  $: controller = getMatchController()
  $: startDate = match.startDate
  $: endDate = match.endDate
  $: if (startDate && endDate) trimDays()
  $: change = getChangesViewModel(matchInitial, match)
  $: hasChanges = change.changes != null
  $: outstandingChangesWouldNotAffectOtherSide = changesWouldNotAffectOtherSide(change.changes, match)
  $: changesCanBeProposed = hasChanges && !outstandingChangesWouldNotAffectOtherSide && match.status >= MatchStatus.Onboarding
  $: changesCanBeSaved =
    hasChanges && (outstandingChangesWouldNotAffectOtherSide || !match.submitted || match.isCoordinator || match.status < MatchStatus.Onboarding)
  $: if (hasChanges) unsavedForms.add(form)
  else unsavedForms.del(form)
  // for api requests that return list of steps, we need to pass dates in if user is changing them / assume they are not on server and we want to see step assignment based on dates in ui
  $: startDateOverride = match == null || matchInitial == null || matchInitial.startDate == match.startDate ? null : match.startDate
  $: endDateOverride = match == null || matchInitial == null || matchInitial.endDate == match.endDate ? null : match.endDate

  $: isValidStartAndEndDates = (() => {
    const capacity = match == null ? null : match.capacity
    if (capacity == null) return false
    if (match.startDate == null || match.endDate == null) return true
    return (
      validator.dateBetween(match.startDate, capacity.startDate, capacity.endDate) &&
      validator.dateBetween(match.endDate, capacity.startDate, capacity.endDate)
    )
  })()

  $: noMatchUsers = match == null || match.matchUsers == null
  $: students = noMatchUsers ? [] : match.matchUsers.filter(mu => mu.matchRole == MatchRole.Student)
  $: schoolStaff = noMatchUsers
    ? []
    : setStaff(match.matchUsers.filter(mu => [MatchRole.SchoolCoordinator, MatchRole.SchoolFaculty].includes(mu.matchRole)))
  $: healthStaff = noMatchUsers
    ? []
    : setStaff(match.matchUsers.filter(mu => [MatchRole.ClinicCoordinator, MatchRole.Preceptor].includes(mu.matchRole)))
  $: if (activityComponent && matchId > 0) loadActivity()
  $: if (matchId > 0 && controller) loadMatchDetails(null, { isInitial: true })
  $: $windowWidth, titleEl, actionPanelEl, match, initResizeObserver()
  $: matchId, resetStepActions()

  const unsubscribe = sockets.on('UI_MatchModal', ui => {
    // wait event loop in case the modal is already about to do an update
    setTimeout(() => {
      if (ui?.matchId !== match?.matchId) return
      reloadMatchDetailsAndActivity(ui.requestId)
    })
  })

  lockScroll(document.body)

  onDestroy(() => {
    unsubscribe()
    unlockScroll(document.body)
    observer?.disconnect()
    resetStepActions()
  })

  function resetStepActions() {
    $stepActions = {}
  }

  function reloadMatchDetailsAndActivity(requestId = null) {
    return Promise.all([loadMatchDetails(requestId, { refreshListWhenClosed: true }), loadActivity()])
  }

  function setStaff(matchUsers) {
    const matchUsersByUserId = Object.values(_.groupBy(matchUsers, 'userId'))
    const staff = []
    for (const matchUserArray of matchUsersByUserId) {
      const user = matchUserArray[0]
      staff.push(user)
      user.matchRoles = [user.matchRole]
      for (let i = 1; i < matchUserArray.length; i++) {
        const matchUser = matchUserArray[i]
        user.matchRoles.push(matchUser.matchRole)
        if (user.status == null && matchUser.status != null) {
          user.status = matchUser.status
          user.statusName = matchUser.statusName
        }
      }

      // TODO(nursing-phase2): Remove all of these and prefer just using `user.matchRoles` with formatting functions.
      user.roles = _.uniq(user.matchRoles).map(mr => ({ name: formatEnumValue(MatchRole, mr), value: mr }))
      user.roleNames = user.roles.map(r => r.name)
      user.roleValues = user.roles.map(r => r.value)
    }
    return staff
  }

  function initResizeObserver() {
    if (titleEl == null || actionPanelEl == null || observer != null) return
    observer = new ResizeObserver(() => {
      setHeights()
    })
    observer.observe(titleEl)
    observer.observe(actionPanelEl)
  }

  function setHeights() {
    if (titleEl == null || actionPanelEl == null) return
    marginTop = `margin-top: ${titleEl ? titleEl.clientHeight + 20 : 70}px;`
    detailsHeightAndMargin = `height: calc(100vh - ${titleEl.clientHeight + 1}px - ${actionPanelEl.clientHeight + 1}px); margin-top: ${
      titleEl.clientHeight + 1
    }px; margin-bottom: ${actionPanelEl.clientHeight}px;`
  }

  function onMatchModalScroll(e) {
    scrolledTop = e.target.scrollTop === 0
  }

  function comparableDate(date) {
    return dayjs(date).toDate().getTime()
  }

  function trimDays() {
    match.matchDays = match.matchDays.filter(
      d => comparableDate(d.date) >= comparableDate(match.startDate) && comparableDate(d.date) <= comparableDate(endDate)
    )
  }

  async function refreshSteps() {
    const parameters = {
      matchId: match == null ? null : match.matchId,
      startDate: startDateOverride,
      endDate: endDateOverride,
    }

    // prevent race conditions _and_ tell server to stop working on request
    if (refreshingStepsTask) refreshingStepsTask.abort()

    // TODO: hit endpoint to just get steps...
    refreshingStepsTask = controller.get(parameters, api.noMonitor)
    const res = await refreshingStepsTask
    refreshingStepsTask = null
    // only set fields related to steps so we avoid overrwriting any local changes they have
    // we don't call mergeLocalWithRemote, since this endpoint is a pseudo remote--we send overrides so server can calculate what this schedule would look like. so we don't want to reset matchInitial
    const local = match
    setMatchBasicInfo(local, res)
    setMatchStepInfo(local, res)
    match = local
  }

  async function buildSaveMatchModel() {
    await tick() // so `changes` gets updated (otherwise extending onboarding deadline, for instance, doesn't work)
    return {
      ...change,
      comment: validator.empty(changeComment) ? getDefaultChangeComment(change.changes, matchInitial) : changeComment,
      dueDate: changeDueDate,
    }
  }

  // make a change
  async function saveMatch(skipMatchUsageValidation = false) {
    const body = await buildSaveMatchModel()
    if (skipMatchUsageValidation && body.changes) body.changes.skipMatchUsageValidation = true
    const requestId = api.generateRequestId()
    body.requestId = requestId
    argsByRequestId[requestId] = { hardSet: true }
    argsByRequestId[requestId] = { hardSet: true, startDate: null, endDate: null }
    savingMatch = true
    try {
      await controller.changeMatch({ matchId: match.matchId }, body, {
        monitor: false,
        canHandleRequestError: (response, content) => {
          return !!content.confirmationMessage
        },
      })
      toaster.toast({ message: 'Changes saved', type: 'success', icon: 'edit' })
      await onMatchChanged({ detailsHard: true, activity: true, requestId })
    } catch (error) {
      const message = error?.content?.confirmationMessage
      if (!message) throw error
      confirms.add({
        title: 'Confirmation needed',
        message,
        confirmLabel: `Save changes anyway`,
        onConfirm: () => {
          saveMatch(true)
        },
      })
    } finally {
      savingMatch = false
    }
  }

  async function loadMatchDetails(requestId, args) {
    let { refreshListWhenClosed = false, hardSet = false } = args
    const isInitial = args.isInitial || false
    const argsForThisRequestId = argsByRequestId[requestId]
    const urlParameters = { matchId, startDate: startDateOverride, endDate: endDateOverride }
    if (argsForThisRequestId) {
      refreshListWhenClosed = argsForThisRequestId.refreshListWhenClosed
      hardSet = argsForThisRequestId.hardSet
      if ('startDate' in argsForThisRequestId) urlParameters.startDate = argsForThisRequestId.startDate
      if ('endDate' in argsForThisRequestId) urlParameters.endDate = argsForThisRequestId.endDate
    }
    if (requestId != null) {
      if (loadedDueToRequestIds.includes(requestId)) return
      loadedDueToRequestIds.push(requestId)
    }
    if (refreshListWhenClosed || !hardSet) matchChanged = true
    if (matchId == null) return

    reloadingDetailsTask?.abort()
    reloadingDetailsTask = controller.get(urlParameters, { monitor: isInitial })
    try {
      const res = await reloadingDetailsTask
      reloadingDetailsTask = null
      // use  hardSet if you want to discard any un-saved changes
      // use !hardSet if you want to merge in from the server
      if (hardSet || isInitial) setMatch(res)
      else mergeLocalWithRemote(res)
    } catch {
      reloadingDetailsTask = null
      if (isInitial) {
        /*
          if fail to load, go back to list. there's a bug where svelte slots don't get updated correctly.
          so if you get 403 opening match, the router won't update if you try to open a different match.
          svelte really needs to resolve update issue(s) with slot...
          svelte bug probably: https://github.com/sveltejs/svelte/issues/4165
          */
        await tick()
        const currentError = $modelState == null ? null : $modelState[0]
        close(false)
        await tick()
        $modelState = currentError
      }
    }
  }

  function loadActivity() {
    return activityComponent?.load()
  }

  function setMatch(remote) {
    if (remote == null) return
    // for some reason it will put "undefined" in the textarea for syllabus...so just set them to empty strings if they're null or undefined
    remote.syllabus ??= ''
    remote.studentComments ??= ''
    match = remote
    matchInitial = _.cloneDeep(match)
  }

  function mergeLocalWithRemote(remote) {
    if (hasChanges) {
      // if have local changes, attempt to merge. show conflicts for things that can't be merged
      const mergedMatch = mergeMatch(matchInitial, match, remote)
      if (mergedMatch.hasConflicts) {
        const conflictedKeys = Object.values(mergedMatch.conflicts).map(c => c.keyUIName)
        toaster.toast({ message: `There are conflicted changes for ${toFriendlyList(conflictedKeys)}`, type: 'warning', icon: 'alert-triangle' })
        conflicts = mergedMatch.conflicts
      }
      setMatch(remote) // re-set "initial" match to be latest remote
      match = mergedMatch.mergeResult // apply their local changes
    } else {
      // if no local changes, simply update
      setMatch(remote)
    }
  }

  let matchChanging = false
  async function onMatchChanged(options) {
    matchChanged = true // took action on the match, so mark this as changed so list gets reloaded when they nav away from this match
    const tasks = []
    if (options.details || options.detailsHard)
      tasks.push(loadMatchDetails(options.requestId, { refreshListWhenClosed: true, hardSet: options.detailsHard }))
    if (options.activity) tasks.push(loadActivity())
    matchChanging = true
    return Promise.all(tasks).finally(() => (matchChanging = false)) // so calling code has ability to await the match modal updating
  }

  function close(changed) {
    const changedIsBool = changed === true || changed === false // i.e. it's not an Event object from an event handler
    if (!changedIsBool) changed = matchChanged // if nothing is passed or if an Event object is passed, use matchChanged value
    // todo fire something that dashboard view types can listen for & know to update data if there were changes
    dispatch('close', changed)
  }

  async function onSaveExpectedHours(num, userIds) {
    // when expected hours are updated, update the match viewmodel accordingly
    await api.hourLog.setExpectedHours({ matchId: match.matchId }, { userIds, totalExpectedHours: num }, api.noMonitor)
    matchChanged = true
    match.matchUsers = match.matchUsers.map(u => {
      if (userIds.includes(u.userId)) {
        u.totalExpectedHours = num
      }
      return u
    })
  }
</script>

<style lang="scss">
  .match-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 1050;
  }

  .main {
    overflow-y: auto;
    width: 75%;
  }

  .details {
    padding: 20px;

    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(> .form-group:last-child),
    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(> .form-group .collapsible:last-child) {
      margin-bottom: 0 !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    .main {
      width: 100%;
    }
  }
</style>
