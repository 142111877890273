{#if show}
  <div>
    {#if detailedScheduleCanBeSetBy === DetailedScheduleCanBeSetBy.NoOne}
      No one
    {:else if detailedScheduleCanBeSetBy === DetailedScheduleCanBeSetBy.ClinicalSiteCoordinator}
      Only <span class="text-health">clinical site coordinators</span>
    {:else if detailedScheduleCanBeSetBy === DetailedScheduleCanBeSetBy.AnyCoordinator}
      <span class="has-tooltip" use:tip={anyCoordinatorTooltip}>Any coordinator</span>
    {:else if detailedScheduleCanBeSetBy === DetailedScheduleCanBeSetBy.AnyCoordinatorOrStudentIfTheyRequestedTheMatch}
      <span class="has-tooltip" use:tip={anyCoordinatorTooltip}>Any coordinator</span>
      or <span class="text-primary">student</span> (if they requested the rotation)
    {:else if detailedScheduleCanBeSetBy === DetailedScheduleCanBeSetBy.AnyCoordinatorOrStudent}
      <span class="has-tooltip" use:tip={anyCoordinatorTooltip}>Any coordinator</span> or <span class="text-primary">student</span>
    {/if}
    can set detailed schedules

    <div class="hide">
      <div bind:this={anyCoordinatorTooltipElem} class="text-left">
        Both <span class="text-health">clinical site coordinators</span> and <span class="text-school">school coordinators</span>
      </div>
    </div>
  </div>
{/if}

<script>
  import { DetailedScheduleCanBeSetBy } from 'config/enums.js'
  import tip from 'decorators/tip.js'

  export let capacity
  export let show = true

  let anyCoordinatorTooltipElem

  $: detailedScheduleCanBeSetBy = capacity.detailedScheduleCanBeSetBy

  $: anyCoordinatorTooltip = anyCoordinatorTooltipElem ? { content: anyCoordinatorTooltipElem, options: { theme: 'light-gray-scrollable' } } : null
</script>
