<Form on:submit={submit}>
  <div class="modal-body">
    <div class="flex-row g2 mb2">
      <div style="margin-top: 26px"><ColorPicker bind:value={input.color} dataTest="shift-color" /></div>
      <FormGroup class="flex-grow" valid={validator.required(input.name)}>
        <label>
          Window name
          <RequiredMarker />
        </label>
        <InputText name="shift-name" bind:value={input.name} autofocus maxlength={50} />
      </FormGroup>
    </div>

    <FormGroup>
      <div class="flex-row flex-align-center g05 mb05">
        <label class="m0" for="capacity-description">Window description</label>
        <Help>Optionally provide more details about this availability window</Help>
      </div>
      <InputTextarea name="capacity-description" bind:value={input.description} />
    </FormGroup>

    <div class="flex-row g2">
      <FormGroup valid={input.maxMatchCountPerDay == null || validator.int(input.maxMatchCountPerDay)}>
        <div class="flex-row flex-align-center g05 mb05">
          <label class="flex-row flex-align-center g05 m0" for="maximum-students">
            <Icon sm name="rotation" autoColor />
            Maximum {capacity.allowGroups ? 'groups' : 'students/learners'} per day
            <RequiredMarker />
            <Help>How many rotations per day should this window be limited to?</Help>
          </label>
        </div>
        <InputOptionalNumber
          name="shift-max-group-matches-per-day"
          bind:value={input.maxMatchCountPerDay}
          min={1}
          noValueLabelClass="flex-row flex-align-center g05"
          noValueIsNull
        >
          <svelte:fragment slot="no-value-label">
            <Icon lg name="infinity" class="text-info" />
            <span>Unlimited</span>
          </svelte:fragment>
        </InputOptionalNumber>
      </FormGroup>
      {#if capacity.allowGroups}
        <FormGroup valid={input.maxStudentsInGroup == null || validator.int(input.maxStudentsInGroup)}>
          <div class="flex-row flex-align-center g05 mb05">
            <label class="flex-row flex-align-center g05 m0" for="maximum-students">
              <Icon name="graduation-cap" class="color-text-teal" />
              Maximum students per group
              <RequiredMarker />
              <Help>How many students per group should this window be limited to?</Help>
            </label>
          </div>
          <InputOptionalNumber
            name="shift-max-students-per-group"
            bind:value={input.maxStudentsInGroup}
            min={1}
            noValueLabelClass="flex-row flex-align-center g05"
            noValueIsNull
          >
            <svelte:fragment slot="no-value-label">
              <Icon lg name="infinity" class="text-info" />
              <span>Unlimited</span>
            </svelte:fragment>
          </InputOptionalNumber>
        </FormGroup>
      {/if}
    </div>
    <FormGroup>
      <label>Restrict total number of days per week that rotations can use</label>
      <InputRadioGroup
        name="has-match-day-limit-per-week"
        bind:value={input.hasMatchDayLimitPerWeek}
        options={[{ value: false }, { value: true }]}
        lastOptionClass="mb0"
        let:option
      >
        {#if option.value}
          <div class="flex-row flex-align-center g1">
            <span>Restrict to</span>
            <InputSelect
              inline
              sm
              placeholder={daysOfWeek[0].name}
              selected
              options={daysOfWeek}
              bind:value={input.matchDayLimitPerWeek}
              name="match-day-limit-per-week"
            />
            <span>days</span>
          </div>
        {:else}
          Allow all selected opportunity days to be filled
        {/if}
      </InputRadioGroup>
    </FormGroup>
    <FormGroup>
      <label>Time</label>
      <InputOptionalTimePicker name="shift-times" bind:startValue={input.startTime} bind:endValue={input.endTime} showTotal range
      ></InputOptionalTimePicker>
    </FormGroup>

    <div class="flex-row g2">
      <FormGroup clas="flex-column">
        <div class="flex-row flex-align-center g05 mb05">
          <Icon name="hospital" class="color-text-orange" />
          <label for="locations" class="m0">Location</label>
          <Help>The location where this availability window takes place</Help>
        </div>
        <InputRadioGroup class="flex-column" bind:value={input.availableToAllLocations} options={[{ value: true }, { value: false }]} let:option>
          {#if option.value}
            Available to all locations
          {:else}
            <SimpleOrgPicker
              bind:value={input.locationId}
              ancestorOrgId={$persona.orgId}
              placeholder="Select availability window location"
              name="locations"
            />
          {/if}
        </InputRadioGroup>
        {#if shiftLocationEffectivelyDeleted}
          <ShiftLocationDeletedMessage
            wasExplicitlyDeleted={shiftLocationExplicitlyDeleted}
            wasEffectivelyDeleted={shiftLocationEffectivelyDeleted}
            tipOptions={{ placement: 'right' }}
          />
        {/if}
      </FormGroup>

      <FormGroup class="flex-grow">
        <div class="flex-row flex-align-center g05 mb05">
          <label for="shift-guests" class="m0">
            <Icon name="school" class="color-text-blue" />
            Schools
          </label>
        </div>
        <InputRadioGroup
          name="available-to-all-guests"
          class="flex-column"
          bind:value={input.availableToAllCapacityGuests}
          options={[{ value: true }, { value: false }]}
          let:option
        >
          {#if option.value}
            Available to all schools
          {:else}
            <SimpleCapacityGuestPicker bind:value={input.selectedGuestOrgs} {capacity} multiple />
          {/if}
        </InputRadioGroup>
      </FormGroup>
    </div>

    {#if capacity.postToSearchPage}
      <FormGroup>
        <label class="flex-row flex-align-center g05" for="capacity-shift-staff">
          <Icon name="staff-settings" class="color-text-orange" />
          Staff visible to applicants
        </label>
        <SimpleStaffPicker bind:value={input.selectedUserIds} options={capacity.staff} multiple name="capacity-shift-staff" />
      </FormGroup>
    {/if}

    <FormGroup>
      <label>Schedules</label>
      <div class="mb1">
        <IconTextLink text="Add schedule" onClick={addShiftDay} dataTest="add-schedule" />
      </div>
      {#if input.shiftDays.length === 0}
        <Alert type="info">
          This availability window will have the same availability as the opportunity itself (<StartAndEndDate
            startDate={capacity.startDate}
            endDate={capacity.endDate}
            class="inline"
          />) unless you add a schedule.
        </Alert>
      {:else}
        <div class="shift-day-cards">
          {#each input.shiftDays as shiftDay}
            <ShiftDayCard {capacity} shift={input} {shiftDay}>
              <svelte:fragment slot="actions">
                <a href={null} on:click={() => editShiftDay(shiftDay)}>
                  <Icon lg name="edit" class="text-primary" title="Edit schedule" />
                </a>
                <a href={null} on:click={() => onShiftDayDelete(shiftDay)}>
                  <Icon lg name="delete" class="text-danger" title="Delete schedule" />
                </a>
              </svelte:fragment>
            </ShiftDayCard>
          {/each}
        </div>
      {/if}

      {#if editingShiftDay}
        <Modal lg on:close={resetEditingShiftDay}>
          <h4 slot="title">
            {#if editShiftDay.isNew}
              Add schedule
            {:else}
              Edit schedule
            {/if}
          </h4>
          <ShiftDayFormModal
            {capacity}
            bind:shift={input}
            bind:shiftDay={editingShiftDay}
            onSubmit={onShiftDaySubmit}
            onDelete={onShiftDayDelete}
            onClose={resetEditingShiftDay}
          />
        </Modal>
      {/if}
    </FormGroup>
  </div>
  <div class="modal-footer">
    <div class="flex-row flex-align-center g05">
      <Btn type="submit" class="btn-primary" dataTest="add-shift" icon={input.isNew ? 'plus' : 'check'} disabled={!hasChanges}>
        {#if input.isNew}
          Add availability window
        {:else}
          Update availability window
        {/if}
      </Btn>
      <Btn icon="cancel" on:click={onClose}>Cancel</Btn>

      {#if !input.isNew && capacity.shifts.length > 1}
        <div class="flex-grow" />
        <Btn
          icon="delete"
          on:click={deleteShift}
          class="btn-danger"
          confirm={{
            title: 'Delete availability window',
            message: `Are you sure you want to delete the availability window <strong>${input.name}</strong>?`,
            confirmLabel: 'Delete',
            confirmClass: 'btn-danger',
            confirmIcon: 'delete',
          }}
        >
          Delete
        </Btn>
      {/if}
    </div>
  </div>
</Form>

<script>
  import { buildOption } from 'services/option-builder.js'
  import { mergeShiftDays } from 'services/calendar-service.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import ColorPicker from 'components/fields/ColorPicker.svelte'
  import colorService from 'services/color-service.js'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import InputOptionalNumber from 'components/InputOptionalNumber.svelte'
  import InputOptionalTimePicker from 'components/InputOptionalTimePicker.svelte'
  import InputRadioGroup from './fields/InputRadioGroup.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import ShiftDayCard from 'components/ShiftDayCard.svelte'
  import ShiftDayFormModal from 'components/ShiftDayFormModal.svelte'
  import ShiftLocationDeletedMessage from 'components/ShiftLocationDeletedMessage.svelte'
  import SimpleCapacityGuestPicker from 'components/SimpleCapacityGuestPicker.svelte'
  import SimpleOrgPicker from 'components/SimpleOrgPicker.svelte'
  import SimpleStaffPicker from 'components/SimpleStaffPicker.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import validator from 'services/validator.js'

  export let shift
  export let capacity
  export let onSubmit
  export let onDelete
  export let onClose

  let input
  let inputInitial
  let editingShiftDay
  const daysOfWeek = []
  for (let i = 1; i <= 7; i++) daysOfWeek.push(buildOption(i, i))

  $: shift, setInput()
  $: hasChanges = !validator.equals(comparableInput(input), comparableInput(inputInitial))
  $: shiftLocationExplicitlyDeleted = shift.locations?.some(o => o.orgExplicitlyDeleted)
  $: shiftLocationEffectivelyDeleted = shift.locations?.some(o => o.orgEffectivelyDeleted)

  function setInput() {
    input = _.cloneDeep(shift)
    input.shiftId ??= null
    input.name ??= null
    input.maxMatchCountPerDay ??= null
    input.maxStudentsInGroup ??= null
    input.color ??= colorService.getBestAvailableColor(capacity.shifts.map(s => s.color))
    input.startTime ??= null
    input.endTime ??= null
    input.shiftDays ??= []
    input.locationId ??= null
    input.hasMatchDayLimitPerWeek = !!input.matchDayLimitPerWeek
    input.matchDayLimitPerWeek ??= 1
    input.staff ??= []
    input.guests ??= []
    input.selectedGuestOrgs = input.guests.length ? capacity.guests.filter(cg => input.guests.map(sg => sg.guestOrgId).includes(cg.guestOrgId)) : []
    if (input.guests.some(g => !g.guestOrgId)) input.selectedGuestOrgs.push(capacity.guests.find(cg => !cg.guestOrgId))
    input.selectedUserIds = input.staff?.map(s => s.userId) ?? []
    input.availableToAllLocations = !input.locationId
    input.availableToAllCapacityGuests = !input.guests.length
    inputInitial = _.cloneDeep(input)
  }

  function comparableInput(value) {
    const comparable = _.cloneDeep(value)
    delete comparable.isNew
    return comparable
  }

  function resetEditingShiftDay() {
    editingShiftDay = null
  }

  function editShiftDay(shiftDay) {
    editingShiftDay = shiftDay
  }

  function addShiftDay() {
    const shiftDayId = Math.min(0, ...input.shiftDays.map(day => day.shiftDayId)) - 1
    editShiftDay({
      isNew: true,
      shiftDayId,
    })
  }

  function onShiftDaySubmit(shiftDay) {
    shiftDay.isNew = false
    const index = input.shiftDays.findIndex(day => day.shiftDayId === shiftDay.shiftDayId)
    if (index < 0) input.shiftDays.push(shiftDay)
    else input.shiftDays[index] = shiftDay
    input.shiftDays = input.shiftDays
  }

  function onShiftDayDelete(shiftDay) {
    input.shiftDays = input.shiftDays.filter(day => day.shiftDayId !== shiftDay.shiftDayId)
  }

  function submit() {
    cleanInput()
    onSubmit(input)
    onClose()
  }

  function deleteShift() {
    onDelete(shift)
    onClose()
  }

  function cleanInput() {
    input.shiftDays = mergeShiftDays([], input.shiftDays)
    if (!input.hasMatchDayLimitPerWeek) input.matchDayLimitPerWeek = null
    if (input.availableToAllLocations) input.locationId = null
    if (input.availableToAllCapacityGuests) input.selectedGuestOrgs = []
    input.staff = []
    input.guests = []
    if (input.selectedUserIds?.length) {
      for (const userId of input.selectedUserIds) {
        const staff = capacity.staff.find(cg => cg.userId === userId)
        if (staff) input.staff.push(staff)
      }
    }
    input.guests = input.selectedGuestOrgs

    delete input.hasMatchDayLimitPerWeek
    delete input.availableToAllCapacityGuests
    delete input.availableToAllLocations
    delete input.selectedUserIds
    delete input.selectedGuestOrgs
  }
</script>

<style>
  .shift-day-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
  }
</style>
