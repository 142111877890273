<span class={className}>
  <Icon name={helper.icon} class="text-{helper.color}" />
  <span class={labelClass}>{helper.label}</span>
</span>

<script>
  import matchStatusHelper from 'services/match-status-helper.js'
  import Icon from 'components/Icon.svelte'

  export let status
  let className = 'nowrap'
  export { className as class }
  export let labelClass = 'strongish'

  $: helper = matchStatusHelper.get(status)
</script>
