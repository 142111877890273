<div class:details-view={detailsView} style={detailsView ? (isOpen ? 'background-color: #daf3d8' : 'background-color: #eee') : ''}>
  {#if detailsView}
    <div class="flex text-center" style={isOpen ? 'color: #034f05;' : 'color: #000;'}>
      <div>
        <strong>
          {#if isOpen}
            Status: Visible
          {:else}
            Status: Draft
          {/if}
        </strong>
      </div>
      <div>
        <strong>
          {#if openForScheduling}
            Open for scheduling
          {:else}
            Not visible
          {/if}
        </strong>
      </div>
      {#if openForScheduling}
        <span style="color: #034f05;">
          <Icon name="calendar-check" x2 />
        </span>
      {:else}
        <span style="color: #000;">
          <Icon name="hide" x2 />
        </span>
      {/if}
    </div>
  {:else if isSchool || isHealth}
    {#if !capacity?.guests?.length}
      <div use:tip={'This opportunity doesn’t have any schools'}>
        <Icon name="alert-triangle" lg class="text-warning" />
        <div class={textClass}>
          Action <br />
          needed
        </div>
      </div>
    {:else if matchCapacityFull || studentCapacityFull}
      <Icon name="calendar-check" lg class="text-success" />
      {#if matchCapacityFull}
        {#if isAnyMatchAvailableInPool(capacity)}
          <Icon name="alert" lg title="New rotations may be available. Please check again in a few days." />
        {/if}
      {/if}
      <div class={textClass}>Full</div>
    {:else if isUpcoming}
      <Icon name="calendar" lg class="text-warning" />
      <div class={textClass}>Upcoming</div>
    {:else if isEnded}
      <div use:tip={'Opportunity window ended'}>
        <Icon name="calendar-xmark" lg class="text-danger" />
        <div class={textClass}>Ended</div>
      </div>
    {:else if isOpen}
      <div use:tip={'Open for scheduling'}>
        <Icon name="calendar" lg class="color-text-light-green" />
        <div class={textClass} data-test="status-open">Open</div>
      </div>
    {:else if isDraft}
      <div use:tip={'Draft'}>
        <Icon name="edit" lg class="color-text-steel" />
        <div class={textClass} data-test="status-draft">Draft</div>
      </div>
    {/if}
  {/if}
</div>

<script>
  import { isMatchCapacityFull, isStudentCapacityFull, isAnyMatchAvailableInPool } from 'services/capacity-usage.js'
  import { PersonaType } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona'
  import tip from 'decorators/tip.js'

  export let capacity
  export let detailsView = false

  const textClass = 'initialism'

  $: openForScheduling = capacity.openForScheduling
  $: hasStarted = capacity.hasStarted
  $: hasEnded = capacity.hasEnded
  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: isSchool = $persona.personaType === PersonaType.SchoolStaff

  // Status is...
  $: isOpen = openForScheduling
  $: isUpcoming = openForScheduling && !hasStarted && !hasEnded
  $: isEnded = hasEnded
  $: matchCapacityFull = isMatchCapacityFull(capacity)
  $: studentCapacityFull = isStudentCapacityFull(capacity)

  $: isDraft = !openForScheduling
</script>

<style>
  .details-view {
    display: flex;
    height: 96px;
    padding: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
  }
</style>
