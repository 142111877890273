{#if files?.length}
  <div class="mb1 pt05">
    {#each files as f}
      <div class="flex-row flex-align-center g05">
        <DownloadTextLink file={f} bind:this={downloadTextLinkComponent} hideDoneBtn>
          <svelte:fragment slot="footer">
            <slot name="file-viewer-footer" />
          </svelte:fragment>
        </DownloadTextLink>

        {#if canDelete}
          <div class="flex-grow">
            <Btn
              confirm={{
                title: 'Remove file',
                message: 'Are you sure you want to remove this file?',
                confirmLabel: 'Remove',
                confirmClass: 'btn-danger',
              }}
              clearBtnStyling
              class="pull-right text-danger"
              on:click={() => onDeleteFile(f)}
              title="Remove file"
              dataTest="remove-file"
              icon="delete"
              loading={deleting[f.url]}
            />
          </div>
        {/if}
      </div>
      <div class="small em" style="margin-left: 19px;">
        Added <FromNow date={f.dateCreated} localize showTooltip tipOptions={{ placement: 'left', offset: [0, 47] }} />
      </div>
    {/each}
  </div>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import DownloadTextLink from 'components/DownloadTextLink.svelte'
  import FromNow from 'components/FromNow.svelte'

  export let files
  export let onDeleteFile
  export let canDelete
  export let deleting
  export let downloadTextLinkComponent
</script>
