import {
  CriteriaFilterType,
  DashboardViewCategory,
  FeatureType,
  FilterType,
  PersonaType,
  StepSubmissionListSortProperty,
  StepSubmissionStatus,
} from 'config/enums.js'

import api from 'services/api.js'

export default {
  category: DashboardViewCategory.MySteps,
  featureTypes: [FeatureType.MySteps, FeatureType.SchoolComplianceRequirements],
  availableToStudents: true,
  name: 'My steps',
  // permission: // any logged in user can use it
  componentName: 'MyStepsReport',
  icon: 'my-steps',

  criteria: async persona => {
    let filters = []
    if (persona.personaType === PersonaType.Student) {
      filters = [
        {
          type: FilterType.StepSubmissionStatus,
          config: {
            statuses: [StepSubmissionStatus.Incomplete, StepSubmissionStatus.Rejected],
          },
        },
      ]
    } else if (persona.personaType === PersonaType.SchoolStaff) {
      filters = await api.step.getMyStepsDefaultFiltersSchool(api.noMonitor)
    }
    return {
      filters,
      matchFilters: [],
      sortProperty: StepSubmissionListSortProperty.Automatic,
      sortAscending: false,
    }
  },

  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.StepSubmissionFilter,
    },
    {
      prefix: 'match',
      name: 'matchFilters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],

  waitFor: { method: 'POST', route: /\/step-submissions\/list-my-step-submissions/ },
  // testClick: 'run-report-btn',
  testFor: ['my-steps-grid'],
}
