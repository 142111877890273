{#if show}
  <div>
    <CapacityNoNewRequestsWhenRolesLabel {capacity} />
    {#if noNewRequestsWhen == null}
      will always be allowed to submit new requests
    {:else if noNewRequestsWhen === NoNewRequestsWhen.AcceptedMatchCountAtMax}
      will be allowed to submit new requests until the number of <AcceptedRequestsDefinition /> have reached the set maximums
    {:else}
      will be allowed to submit new requests until the number of <SubmittedRequestsDefinition /> have reached the set maximums
    {/if}
  </div>
{/if}

<script>
  import { NoNewRequestsWhen } from 'config/enums.js'
  import AcceptedRequestsDefinition from 'components/AcceptedRequestsDefinition.svelte'
  import CapacityNoNewRequestsWhenRolesLabel from 'components/Capacity.NoNewRequestsWhenRolesLabel.svelte'
  import SubmittedRequestsDefinition from 'components/SubmittedRequestsDefinition.svelte'

  export let capacity
  export let show = true

  $: noNewRequestsWhen = capacity.noNewRequestsWhen
</script>
