{#if capacity}
  <Modal xl on:close={() => routingConfig.close()} dialogStyle={$datePickerIsLastVisibleInput ? 'margin-bottom: 250px' : null}>
    <h4 slot="title">
      {#if capacityGuest}
        Schedule students
      {:else}
        {capacity.name}
      {/if}
    </h4>

    <div class="modal-body">
      {#if capacityGuest}
        <CapacityOverview
          {capacity}
          capacityGuest={actualCapacityGuest ?? capacityGuestBefore}
          bind:capacityGuestBefore
          canSpotlightCapacityGuestChange={!!capacityGuest}
          showName
        />
      {/if}

      <ScheduleStudentsForm
        bind:this={scheduleStudentsForm}
        preselectedCapacity={capacity}
        preselectedCapacityGuest={capacityGuest}
        bind:actualCapacityGuest
        hideCapacityOverview={!!capacityGuest}
        {store}
        {onStudentsScheduled}
      />
    </div>

    <ScheduleStudentsFormButtons {scheduleStudentsForm} {routingConfig} {store} />
  </Modal>
{/if}

<script context="module">
  export function buildScheduleStudentsModalProps(capacitiesOrCapacity, params) {
    const capacityId = Number.parseInt(params.capacityId, 10)
    const capacity = Array.isArray(capacitiesOrCapacity) ? capacitiesOrCapacity.find(c => c.capacityId === capacityId) : capacitiesOrCapacity
    let capacityGuest = null
    if (params.guestOrgId === 'open') {
      capacityGuest = capacity?.guests?.find(cg => !cg.guestOrgId)
    } else if (params.guestOrgId) {
      const guestOrgId = Number.parseInt(params.guestOrgId, 10)
      capacityGuest = capacity?.guests?.find(cg => cg.guestOrgId === guestOrgId)
    }

    return {
      capacityId,
      capacity,
      capacityGuest,
    }
  }
</script>

<script>
  import CapacityOverview from 'pages/authorized/org/Capacity.Overview.svelte'
  import Modal from 'components/Modal.svelte'
  import ScheduleStudentsForm, { buildStore } from 'components/ScheduleStudentsForm.svelte'
  import ScheduleStudentsFormButtons from 'components/ScheduleStudentsFormButtons.svelte'

  export let capacityId
  export let capacity
  export let capacityGuest
  export let routingConfig
  export let selectedCapacityIds

  const store = buildStore()
  const shouldClose = store.shouldClose
  const datePickerIsLastVisibleInput = store.datePickerIsLastVisibleInput

  let scheduleStudentsForm
  let actualCapacityGuest = capacityGuest
  let capacityGuestBefore = capacityGuest

  $: if (capacityId) selectedCapacityIds = [capacityId]

  function onStudentsScheduled() {
    if ($shouldClose) routingConfig?.close()
  }
</script>
