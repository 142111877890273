<FormGroup valid={validator.date(match.startDate) && validator.date(match.endDate) && !validator.dateBefore(match.endDate, match.startDate)}>
  {#if !isValidStartAndEndDates}
    <Alert type="warning">
      <div class="flex-row flex-align-center">
        Date range is outside the opportunity’s date range (<StartAndEndDate
          startDate={match.capacity.startDate}
          endDate={match.capacity.endDate}
          class="flex-row flex-align-center g05"
        />)
      </div>
    </Alert>
  {/if}
  <ConflictingRotations {conflictedRotations} />
  {#if conflicts}
    <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.startDate} />
    <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.endDate} />
    <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.matchDays} />
    <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.alternateDates} />
  {/if}
  <Collapsible label="schedule" name="match-modal-schedule" open={isUnsubmitted && !isLongDateRange} remember={!isUnsubmitted && !isLongDateRange}>
    <div slot="label" class="toggle-label">
      <h3 class="m0 mr1">Schedule</h3>
      {#if match.startDate != null && match.endDate != null}
        <div>{match.startDate} to {match.endDate}</div>
      {/if}
      <div>
        {#each match.shifts as shift}
          {@const shiftLocationExplicitlyDeleted = shift.locations?.some(o => o.orgExplicitlyDeleted)}
          {@const shiftLocationEffectivelyDeleted = shift.locations?.some(o => o.orgEffectivelyDeleted)}
          <Tag class="color-bg-{shift.color}">
            {shift.name}
            {#if shift.timeRange}<strong>{shift.timeRange}</strong>{/if}
            {#if shiftLocationEffectivelyDeleted}
              <ShiftLocationDeletedMessage
                wasExplicitlyDeleted={shiftLocationExplicitlyDeleted}
                wasEffectivelyDeleted={shiftLocationEffectivelyDeleted}
                class="pull-right ml1"
                message={null}
              />
            {/if}
          </Tag>
        {/each}
      </div>
    </div>
    <div class="mb3">
      <div class="mb1">
        <MatchDatePicker
          isExisting
          bind:startDate={match.startDate}
          bind:endDate={match.endDate}
          bind:matchDays={match.matchDays}
          {refreshStepsDebounced}
          capacity={match.capacity}
          {match}
          {submitted}
          {matchInitial}
        />
      </div>
      <AlternateDates bind:match {matchInitial} {submitted} />
      <FormGroup>
        <SchedulePicker
          bind:match
          bind:matchDays={match.matchDays}
          bind:matchDayConfigs={match.matchDayConfigs}
          disabled={!match.isEditable ||
            ($persona.isStudent &&
              match.capacity &&
              !match.capacity.detailedScheduleCanBeSetBy >= DetailedScheduleCanBeSetBy.AnyCoordinatorOrStudentIfTheyRequestedTheMatch)}
        />
      </FormGroup>
    </div>
  </Collapsible>
</FormGroup>

<script>
  import { getConflictedDates } from 'services/capacity-usage.js'
  import { DetailedScheduleCanBeSetBy, MatchStatus } from 'config/enums.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import AlternateDates from 'components/AlternateDates.svelte'
  import SchedulePicker from 'components/fields/SchedulePicker.svelte'
  import validator from 'services/validator.js'
  import MergeConflictsMatch from 'components/MergeConflictsMatch.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Collapsible from './Collapsible.svelte'
  import ConflictingRotations from 'components/fields/SchedulePicker.ConflictingRotations.svelte'
  import persona from 'stores/persona.js'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import Tag from 'components/Tag.svelte'
  import MatchDatePicker from './MatchDatePicker.svelte'
  import ShiftLocationDeletedMessage from 'components/ShiftLocationDeletedMessage.svelte'

  export let match
  export let matchInitial
  export let conflicts
  export let refreshStepsDebounced
  export let isValidStartAndEndDates
  export let submitted

  const isUnsubmitted = match.status === MatchStatus.Unsubmitted
  const isLongDateRange = dayjs(match.endDate).diff(dayjs(match.startDate), 'days') > 60

  $: conflictedRotations =
    match.otherMatchSchedules.length === 0
      ? []
      : match.otherMatchSchedules
          .map(otherMatch => ({
            ...otherMatch,
            conflictedDates: getConflictedDates(match, otherMatch),
          }))
          .filter(c => c.conflictedDates.length > 0)
</script>

<style>
  .toggle-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .toggle-label > * {
    margin-right: 10px;
  }
</style>
