{#if show && allowGroups}
  <div class="flex-row flex-align-center flex-wrap g05">
    <span>Students</span>
    <strong class="strongish">
      {#if allowStudentsToLeaveGroupMatch}
        CAN
      {:else}
        CANNOT
      {/if}
    </strong>
    <span>leave group rotations once they’ve been added</span>
  </div>
{/if}

<script>
  export let capacity
  export let show = true

  $: allowGroups = capacity.allowGroups
  $: allowStudentsToLeaveGroupMatch = capacity.allowStudentsToLeaveGroupMatch
</script>
