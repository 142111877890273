{#if guest}
  <div class="m0 p1 {isTooltip ? '' : `card card-left-thick-${guest.color}`}" style="width: fit-content">
    {#if !isTooltip}
      <CapacityGuestProfilePictureAndName capacityGuest={guest} nameClass="strong text-lg" useOverflowEllipsis />

      {#if guest.agreement}
        <div class="flex-row flex-align-center g05 mt05" data-test="capacity-guest-card-agreement-name">
          <span class="small text-gray em">via</span>
          <Icon name="agreement" autoColor />
          <span>{guest.agreement.name}</span>
          <AgreementStatusLabel status={guest.agreement.status} class={null} />
        </div>
      {:else if guest.guestOrgId}
        <NoAgreementSpecified />
      {:else}
        <div />
      {/if}

      <CapacityGuestBlockedMessage allowedByHealthSystem={guest.isAllowedByHealthSystem} allowedBySchool={guest.isAllowedBySchool} />
    {/if}
    <div class="{columnView ? 'flex-column flex-align-start' : 'flex-row'} g2">
      <div>
        <p class="mb05 mt05"><strong>General</strong></p>
        <div class={columnView ? 'flex-column flex-align-start' : 'flex-row'}>
          <div class="text-grid flex-align-start">
            <div style="grid-area: icon;">
              <CapacityGroupSizeIcon {capacity} />
            </div>
            <div style="grid-area: text;">
              <div class="flex-row flex-align-center g05">
                {readableNumber(guest.matchCount)} / <Unlimited value={Math.min(guest.maxMatches ?? Infinity, capacity.maxMatches ?? Infinity)} />
                max rotations
              </div>
              {#if capacity.allowGroups}
                <div class="flex-row flex-align-center g05">
                  {readableNumber(guest.studentCount)} / <Unlimited value={guest.maxStudents} />
                  max students
                  {#if !isHealth && guest.maxStudents && !isTooltip}
                    <Help>{maxStudentsHelpText}</Help>
                  {/if}
                </div>
                {#if !isHealth && guest.maxStudents && isTooltip}
                  <HelpBlock class="mb05">{maxStudentsHelpText}</HelpBlock>
                {/if}
                {#if guest.guaranteedMatchCountGranted}
                  <div>
                    {readableNumber(guest.matchCount)} / {guest.guaranteedMatchCountGranted}
                    guaranteed rotations, released when...
                    <em>
                      <AutoReleaseUnscheduledMatchesWhenLabel sm value={guest.autoReleaseUnscheduledMatchesWhen} />
                    </em>
                  </div>
                {:else if isHealth}
                  <div>No guaranteed rotations</div>
                {/if}
              {/if}
            </div>
          </div>
        </div>
        <div class={columnView ? 'flex-column flex-align-start' : 'flex-row'}>
          <div class="text-grid">
            {#if guest.allowsAddingPlaceholderStudents}
              <div style="grid-area: icon;">
                <Icon name="check" />
              </div>
              <div class="text-left" style="grid-area: text;">
                <div>Allows placeholder students</div>
                <em>Does not require real students before start</em>
              </div>
            {:else}
              <div style="grid-area: icon;">
                <Icon name="cancel" />
              </div>
              <div style="grid-area: text;">
                <div>
                  <strong>DOESN’T</strong> allow placeholder students
                </div>
                <em>Requires real students before start</em>
              </div>
            {/if}
          </div>
        </div>
        <p class="mb05"><strong>Disciplines</strong></p>
        <div class="mb05">
          <CapacityDisciplines {capacity} isDetailsView />
        </div>
        <p class="mb05">
          <strong>Request dates </strong>
          <RequestDateHelpText {isTooltip} {isHealth} />
        </p>
        <StartAndEndDate startDate={guest.schedulingStartDate} endDate={guest.schedulingEndDate} />
        <p class="mb05">
          <strong>Confirmation deadline</strong>
          {#if isTooltip}
            <HelpBlock>{confirmationDeadlineHelpText}</HelpBlock>
          {:else}
            <Help>{confirmationDeadlineHelpText}</Help>
          {/if}
        </p>
        <div>
          <Icon name="calendar" class="color-text-blue" />
          {#if guest.confirmDesiredMatchCountByDate}
            Confirmed by {guest.confirmDesiredMatchCountByDate}
          {:else}
            No confirmation necessary
          {/if}
        </div>
      </div>
      <div>
        {#if capacity.shifts?.length}
          <div class="mb05">
            <div class=" flex-row flex-align-center g05 mb0">
              <p class="m0">
                <strong>Availability windows</strong>
                <Badge color="blackText" xs count={guestShifts?.length} />
              </p>
            </div>
          </div>
          {#if isTooltip}
            <ShiftCards {capacity} shifts={guestShifts} guest={isHealth ? null : guest} isDetailsView {isTooltip} />
          {:else}
            <div style="overflow-y:auto; max-height:300px">
              <ShiftCards {capacity} shifts={guestShifts} guest={isHealth ? null : guest} isDetailsView />
            </div>
          {/if}
        {/if}
      </div>
    </div>
  </div>
{/if}

<script>
  import { PersonaType } from 'config/enums.js'
  import { getGuestShifts } from 'services/capacity-usage.js'
  import { readableNumber, pluralCount } from 'services/string-utils.js'
  import AgreementStatusLabel from 'components/AgreementStatusLabel.svelte'
  import AutoReleaseUnscheduledMatchesWhenLabel from 'components/AutoReleaseUnscheduledMatchesWhenLabel.svelte'
  import Badge from 'components/Badge.svelte'
  import CapacityDisciplines from 'components/CapacityDisciplines.svelte'
  import CapacityGroupSizeIcon from 'components/CapacityGroupSizeIcon.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import CapacityGuestBlockedMessage from 'components/CapacityGuestBlockedMessage.svelte'
  import Help from 'components/Help.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import persona from 'stores/persona.js'
  import RequestDateHelpText from 'components/fields/RequestDateHelpText.svelte'
  import ShiftCards from 'components/ShiftCards.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import Unlimited from 'components/Unlimited.svelte'

  export let capacity
  export let guest
  export let isTooltip = false

  const confirmationDeadlineHelpText = 'Guaranteed rotations must be confirmed by this date.'

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: columnView = guestShifts?.length > 1 && isTooltip
  $: guestShifts = getGuestShifts(capacity.shifts, guest)
  $: maxStudentsHelpText = `You can have a maximum of ${pluralCount('student', guest.maxStudents)} across all rotations using this opportunity. Students will be counted once per rotation.`
</script>

<style>
  .text-grid {
    display: grid;
    grid-gap: 5px;
    margin-bottom: 5px;
    grid-template-areas: 'icon text';
  }
</style>
