{#if show}
  <div class="flex-row flex-align-center flex-wrap g05">
    {#if openForScheduling}
      <Icon name="eye" class="text-success" />
    {:else}
      <Icon name="hide" class="text-danger" />
    {/if}
    <span>This opportunity is</span>
    {#if openForScheduling}
      <strong class="text-success uppercase">visible</strong>
    {:else}
      <strong class="text-danger uppercase">not visible</strong>
    {/if}
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'

  export let capacity
  export let show = true

  $: openForScheduling = capacity.openForScheduling
</script>
