<ButtonModalPicker
  bind:this={buttonModalPicker}
  bind:open
  on:changed={_onChangeConfirmed}
  lg
  {disabled}
  {multiple}
  {loadManager}
  {retryMessage}
  {hideSelectAll}
  {confirmButtonLabel}
  valueSelector={o => o.orgId}
  labelSelector={o => o.name}
  options={$lmOrgs}
  optionsForLabel={$lmSelected}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  class={className}
  {allowSelectNull}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
  {onOptionSelected}
  {onOptionDeselected}
>
  <svelte:fragment slot="buttonLabel">
    {@const orgsToDisplay = multiple ? selected : selected ? [selected] : []}
    <!--
      For pickers where we want to clear the selection on confirmation, we
      intend to add them to a sibling component at that time. In the meantime,
      it's distracting to see the temporary values get added to the button
      label, so we should just continue to show the placeholder.
    -->
    {#if !orgsToDisplay.length || clearSelectionOnConfirm}
      <span class="button-modal-picker-text">
        {placeholder}
      </span>
    {:else}
      {#each orgsToDisplay as org}
        <OrgProfilePictureAndName
          name={org.name}
          profilePicture={org.profilePicture}
          relativeName={org.relativeName}
          class="flex-row flex-align-center nowrap flex-grow overflow-hidden"
          {useOverflowEllipsis}
        />
      {/each}
    {/if}
    <Icon name="caret-down" />
  </svelte:fragment>

  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <OrgFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} {featureTypes} {capacityOwnerOrgId}>
      <svelte:fragment slot="after-keyword-search">
        <OrgPickerShowDropdown {featureTypes} />
      </svelte:fragment>
    </OrgFilters>
    <slot name="additional-filter" />

    {#if $lmLoadingFirstPage}
      <div class="text-center mt1">
        <Spinner x3 class="m2" />
      </div>
    {:else if $lmFailedLoadingFirstPage}
      <LoadManagerRetryLink {loadManager} {retryMessage} isFirstPage />
    {:else}
      <InfiniteScroll
        distanceToLoadPage={100}
        {loadManager}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
        dataTest={dataTest + '-items'}
      >
        <!-- TODO(OrgRelationship): Fix the icon and icon color here. -->
        <EmptyPickerSlot
          bind:valueTemp
          {allowSelectNull}
          text={emptyPickerText}
          {multiple}
          {buttonModalPicker}
          iconClass="color-text-steel"
          icon={emptyPickerIcon}
          {dataTest}
          lg={!showMinimal}
        />
        {#if $lmOrgs?.length}
          {#each $lmOrgs as org, i (org.orgId)}
            {@const autodisabledOrgName = autoSelectChildren && autodisabledOrgsMap.get(org.orgId)}
            {@const orgOverride = getOptionOverride?.(org)}
            <PickerSlot
              {i}
              disabled={org.disabled || orgOverride?.disabled || autodisabledOrgName}
              dataTest={dataTest + '-item'}
              class={autodisabledOrgName ? 'pseudo-disabled' : ''}
              {multiple}
              value={org.orgId}
              option={org}
              autoSelectedLabel={autodisabledOrgName ? 'Autoselected' : null}
              {buttonModalPicker}
              lg={!showMinimal}
              disabledMessage={autodisabledOrgName && typeof disabledMessage === 'function' ? disabledMessage(autodisabledOrgName) : disabledMessage}
              bind:valueTemp
              let:isSelected
              let:isHovered
            >
              <slot name="header" {org} slot="header">
                <OrgProfilePictureAndName
                  name={org.name}
                  profilePicture={org.profilePicture}
                  relativeName={org.relativeName}
                  class="flex-row g1 flex-align-center"
                />
              </slot>
              <slot {org} {isSelected} {isHovered}>
                <OrgPickerSlot {org} {isHovered} {isSelected} {featureTypes} {includes} />
              </slot>
            </PickerSlot>
          {/each}
          {#if $lmFailedLoading}
            <LoadManagerRetryLink {loadManager} retryMessage="Failed to load additional organizations." class="mr1" />
          {/if}
        {:else}
          <h4 class="p3 text-center">No organizations found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script>
  import { FilterType, ToManyComparison } from 'config/enums.js'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import LoadManager from 'services/load-manager'
  import LoadManagerRetryLink from 'components/LoadManagerRetryLink.svelte'
  import OrgFilters from 'components/OrgFilters.svelte'
  import OrgPickerShowDropdown from 'components/OrgPickerShowDropdown.svelte'
  import OrgPickerSlot from 'components/OrgPicker.Slot.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import orgsService from 'services/orgs-service.js'
  import personaFilters from 'stores/persona-filters.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'

  // Common picker exports
  export let value
  export let filters = []
  export let hiddenFilters = []
  export let excludedFilterTypes = []

  export let multiple = false
  export let modalTitle = multiple ? 'Select the organizations' : 'Select the organization'
  export let disabled = false
  export let allowSelectNull = false
  export let placeholder = 'None selected'
  export let emptyPickerText = 'No organization'
  export let emptyPickerIcon = null
  let className = ''
  export { className as class }
  export let dataTest = 'org-picker'
  export let interceptors = {}
  export let open = false
  export let featureTypes = null
  export let includeAgreementCount = false
  export let includeAddresses = false
  export let includeActiveCapacityCount = false
  export let includeStudentCount = false
  export let includeTags = false
  export let includeContactInfo = false
  export let includeDescription = false
  export let includeAncestorOrgIds = false
  export let includeChildrenOrgIds = false
  export let useOverflowEllipsis = false
  export let hideSelectAll = false
  export let getOptionOverride = null
  export let disabledMessage = null
  export let options = null
  export let sortProperty = null

  // Specific picker exports
  export let selected = null
  export let capacityOwnerOrgId = null
  export let hasEverBeenFiltered = false
  export let respectOrgMatchPreferences = false
  export let autoSelectChildren = false
  export let confirmButtonLabel
  export let clearSelectionOnConfirm = false
  export let onChangeConfirmed = null

  const loadManager = new LoadManager((...args) => api.org.list(...args), {
    responseListSelector: r => r.orgs,
    responseSelectedListSelector: r => r.selectedOrgs,
    keySelector: o => o.orgId,
  })
  const lmLoadingFirstPage = loadManager.loadingFirstPage
  const lmOrgs = loadManager.results
  const lmSelected = loadManager.selected
  const lmFailedLoadingFirstPage = loadManager.failedLoadingFirstPage
  const lmFailedLoading = loadManager.failedLoading
  const retryMessage = 'Failed to load organizations.'

  const includes = {
    agreementCount: includeAgreementCount,
    addresses: includeAddresses,
    activeCapacityCount: includeActiveCapacityCount,
    studentCount: includeStudentCount,
    tags: includeTags,
    contactInfo: includeContactInfo,
    description: includeDescription,
  }

  let buttonModalPicker = null
  let valueTemp = null
  let autodisabledOrgsMap = null

  $: personaFiltersOrgId = $personaFilters.orgId

  $: show = $showDropdowns.orgPicker
  // TODO: These should be options you can toggle on/off in the picker.
  $: show.activeCapacityCount = includeActiveCapacityCount
  $: show.studentCount = includeStudentCount
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  $: if (filters.length) hasEverBeenFiltered = true
  $: open, personaFiltersOrgId, featureTypes, filters, hiddenFilters, load(0)
  $: if (open) autodisabledOrgsMap = new Map()
  $: options = $lmOrgs
  $: value, valueTemp, $lmOrgs, $lmSelected, setSelectedOrgs()
  $: $lmOrgs, updateAutoDisabledOrgsMap()
  $: canHandleAutoSelectionEvents = multiple && autoSelectChildren && $lmOrgs?.length && valueTemp

  function _onChangeConfirmed() {
    if (!clearSelectionOnConfirm) return
    onChangeConfirmed?.()
    clear()
  }

  function onOptionSelected(selectedOrg) {
    if (!canHandleAutoSelectionEvents) return
    toggleDescendantOrgs(selectedOrg, true)
  }

  function onOptionDeselected(deselectedOrg) {
    if (!canHandleAutoSelectionEvents) return
    toggleDescendantOrgs(deselectedOrg, false)
  }

  function toggleDescendantOrgs(ancestorOrg, disable) {
    const descendantOrgs = orgsService.getFlatChildren(ancestorOrg, $lmOrgs)
    for (const descendantOrg of descendantOrgs) {
      if (disable) autodisabledOrgsMap.set(descendantOrg.orgId, ancestorOrg.name)
      else autodisabledOrgsMap.delete(descendantOrg.orgId)
    }
    autodisabledOrgsMap = autodisabledOrgsMap
    valueTemp = valueTemp
  }

  function buildAllFilters() {
    const allFilters = [...filters, ...hiddenFilters]
    if (featureTypes?.length) allFilters.push({ type: FilterType.FeatureTypes, config: { comparison: ToManyComparison.AllOf, featureTypes } })
    if (respectOrgMatchPreferences) allFilters.push({ type: FilterType.RespectOrgMatchPreferences, config: { capacityOwnerOrgId } })
    return allFilters
  }

  async function load(offset) {
    const filters = buildAllFilters()

    const comparisonArgs = {
      filters,
      capacityOwnerOrgId,
      includeAddresses,
      includeAgreementCount,
      includeContactInfo,
      includeDescription,
      includeAncestorOrgIds,
      includeChildrenOrgIds,
      includeTags,
      includeActiveCapacityCount,
      includeStudentCount,
    }
    if (sortProperty) comparisonArgs.sortProperty = sortProperty

    const alwaysArgs = {
      pageSize: 2,
      ...comparisonArgs,
    }

    const firstPageBody = {
      excludeTotalCount: false,
    }
    const valueAsArray = Array.isArray(value) ? value : [value]
    const selectedOrgIds = valueAsArray.filter(orgId => orgId && !$lmSelected.has(orgId))
    if (selectedOrgIds.length) firstPageBody.selectedOrgIds = selectedOrgIds

    const nextPageBody = {
      excludeTotalCount: true,
    }

    const body = {
      ...alwaysArgs,
    }

    await loadManager.loadIfNecessary(comparisonArgs, offset, { body }, { body: firstPageBody }, { body: nextPageBody })
  }

  function updateAutoDisabledOrgsMap() {
    if (!canHandleAutoSelectionEvents) return
    for (const org of selected) {
      const descendantOrgs = orgsService.getFlatChildren(org, $lmOrgs)
      for (const descendantOrg of descendantOrgs) {
        if (!autodisabledOrgsMap.get(descendantOrg.orgId)) {
          autodisabledOrgsMap.set(descendantOrg.orgId, org.name)
        }
      }
    }
  }

  function setSelectedOrgs() {
    selected =
      valueTemp == null || $lmOrgs == null || $lmSelected == null
        ? null
        : multiple
          ? valueTemp.map(v => loadManager.getCachedValue(v)).filter(Boolean)
          : loadManager.getCachedValue(valueTemp)
  }

  export function clear() {
    value = null
    valueTemp = multiple ? [] : null
    autodisabledOrgsMap?.clear()
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>
