{#if show}
  <div>
    {#if match.capacity && !match.capacity.detailedScheduleCanBeSetBy < DetailedScheduleCanBeSetBy.AnyCoordinatorOrStudentIfTheyRequestedTheMatch && match.isEditable && match.allowChanges}
      <HelpBlock>
        You can set the start and end date above, but this opportunity doesn’t allow students or school coordinators to set a daily schedule.
        {match.org.name}
        staff will configure schedule details as needed.
      </HelpBlock>
    {/if}
    {#if !disabled}
      {#if noExplicitDays}
        <Alert type="warning">{implicitMsg(match, matchPerStudent)}</Alert>
      {/if}
    {/if}
    <div class="top-controls">
      {#if filledCapacity}
        <div data-test="full-condensed-toggle">
          <BtnGroupPicker
            bind:value={showDetail}
            options={[
              { value: true, label: 'Full', title: 'Edit individual days inline' },
              { value: false, label: 'Condensed', title: 'Show most pertinent information' },
            ]}
          />
        </div>
        {#if !disabled}
          <Tip>{dragSelectTip}</Tip>
        {/if}
      {/if}
    </div>

    {#if loading}
      <Spinner />
    {:else if filledCapacity}
      <Alert bind:show={noDaysAvailableInSelection} slide closable timeToLive={5000}>
        No shifts are available on the
        {pluralCount('day', noDaysAvailableInSelection.size)}
        you selected.
      </Alert>
      <CalendarControls bind:start bind:end />
      <Calendar
        let:day
        startDate={start}
        endDate={end}
        min={startDate}
        max={endDate}
        bind:selected={selectedDays}
        showMonthStartOfWeek={showDetail}
        {dayClasses}
        dateSelector={date => isDateExcluded(date)}
        disabledClass="excluded"
        allDaysClass="g05"
        bind:isDragSelectingDays
      >
        <div slot="icons">
          <DayWarnings busy={dateConflicts[day.formatted]} buckets={filledCapacity && filledCapacity[day.formatted]} />
        </div>
        {#if formattedDateExceptions.includes(day.formatted)}
          <div class="flex flex-column flex-align-center">Opportunity exception</div>
        {/if}

        {#if match.capacity.startDate === day.formatted}
          <div class="flex flex-column flex-align-center">Opportunity starts</div>
        {/if}

        {#if shiftDayLookUp[day.formatted]}
          {#each guestShifts as shift}
            {#if isShiftSelectable(day.formatted, shift.shiftId)}
              {#if shouldMarkDateAsFull(day.formatted, shift.shiftId)}
                <div class="flex flex-column flex-align-center">Full</div>
              {/if}
              {#if !isDateExcluded(day.formatted)}
                <Day
                  {matchDayConfigs}
                  {match}
                  {shift}
                  {schedulableUsers}
                  {matchDaysDict}
                  {allowsPlaceholders}
                  day={day.formatted}
                  bucket={filledCapacity && filledCapacity[day.formatted] ? filledCapacity[day.formatted][shift.shiftId] : null}
                  {showDetail}
                  disabled={disabled || day.disabled}
                  shiftDayInfo={shiftDayLookUp[day.formatted][shift.shiftId]}
                  on:toggleShift={onToggleShift}
                  on:togglePerson={onTogglePerson}
                  on:updateMatchDayConfigs={onUpdateMatchDayConfigs}
                  {isDragSelectingDays}
                />
              {/if}
            {/if}
          {/each}
        {/if}
      </Calendar>

      {#if dayConfigOpen}
        <DaysConfig
          bind:selectedDays
          bind:match
          bind:matchDays
          {matchDayConfigs}
          {availableShifts}
          {schedulableUsers}
          {matchPerStudent}
          {allowsPlaceholders}
          on:toggleShift={onToggleShift}
          on:togglePerson={onTogglePerson}
          on:updateMatchDayConfigs={onUpdateMatchDayConfigs}
        />
      {/if}
    {/if}
  </div>
{/if}

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Tip from 'components/Tip.svelte'
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'
  import Spinner from 'components/Spinner.svelte'
  import Calendar from 'components/Calendar.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Day from 'components/fields/SchedulePicker.Day.svelte'
  import DayWarnings from 'components/fields/SchedulePicker.DayWarnings.svelte'
  import DaysConfig from 'components/fields/SchedulePicker.DaysConfig.svelte'
  import { dragSelectTip } from 'decorators/drag-and-select.js'
  import api from 'services/api.js'
  import validator from 'services/validator.js'
  import {
    toggleShift,
    togglePerson,
    updateMatchDayConfigs,
    addMatchToCapacityUsage,
    implicitMsg,
    getConflictedDates,
    getGuestShifts,
    buildShiftDayLookup,
    buildMatchDayLookup,
    getSchedulableUsers,
  } from 'services/capacity-usage'
  import { DetailedScheduleCanBeSetBy, MatchStatus, PersonaType } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import dateService from 'services/date-service.js'
  import CalendarControls from 'components/CalendarControls.svelte'
  import persona from 'stores/persona.js'

  export let capacity
  export let match
  export let matchDays
  export let matchDayConfigs
  export let disabled = false
  export let show = true
  export let showDetail = false
  export let matchPerStudent = false
  export let shiftId = null
  export let capacityGuest = null

  const loadFilledCapacityDebounced = _.debounce(loadFilledCapacity, 200)
  let loading = false
  let filledCapacityOthers = null
  let filledCapacity = null
  let selectedDays = disabled ? null : new Set()
  let capacityPrev = null
  let noDaysAvailableInSelection = false
  let dayConfigOpen = false
  let start
  let end
  let formattedDateExceptions = []
  let fullDateExcluded = []
  let scheduledMatchDays = []
  let isDragSelectingDays = false

  $: allowsPlaceholders = (capacityGuest ?? match.capacityGuest)?.allowsAddingPlaceholderStudents ?? false
  $: personaTypeIsSchool = $persona.personaType === PersonaType.SchoolStaff
  $: noExplicitDays = matchDays.length === 0 && (!matchDayConfigs?.length || matchDayConfigs.filter(phs => phs.placeholderStudentCount).length === 0)
  $: startDate = match.startDate
  $: endDate = match.endDate
  $: if (!disabled && startDate && endDate && selectedDays) trimSelectedDays()
  $: capacity = match.capacity
  $: capacitySerialized = JSON.stringify(capacity)
  $: matchStatus = match.matchStatus || MatchStatus.Unsubmitted
  $: matchId = match.matchId
  $: schedulableUsers = getSchedulableUsers(match.matchUsers)
  $: schedulableUserIds = Object.keys(schedulableUsers)
  $: scheduledMatchDays = matchDays
  $: matchDaysDict = buildMatchDayLookup(scheduledMatchDays)
  $: startDate, endDate, setInitialStartEnd(), loadFilledCapacityDebounced()
  $: if (capacitySerialized !== capacityPrev) {
    // when capacity changes (can happen on ScheduleStudents page), get usage for the capacity
    capacityPrev = capacitySerialized
    loadFilledCapacityDebounced()
  }
  $: matchStatus, filledCapacityOthers, matchDays, setFilledCapacity()
  // TODO: we should infinite scroll the schedule picker and not load all the data at once--some rotations in the system are several years long...for now, only showing yellow triangle for first 90 days of conflicts
  $: dateConflicts = (() => {
    const result = {}
    for (const otherMatch of match.otherMatchSchedules) {
      const conflictedDates = getConflictedDates(match, otherMatch)
      for (const conflict of conflictedDates) {
        if (conflict.from) {
          // range of date conflicts
          let d = conflict.from
          const maxToShow = 90
          let i = 0
          while (d.isSameOrBefore(conflict.to)) {
            i++
            if (i > maxToShow) break
            const key = d.format('M/D/YYYY')
            if (result[key] == null) result[key] = []
            result[key].push(otherMatch)
            d = d.add(1, 'day')
          }
        } else {
          // single date conflict
          if (result[conflict] == null) result[conflict] = []
          result[conflict].push(otherMatch)
        }
      }
    }
    return result
  })()
  $: shiftDayLookUp = buildShiftDayLookup(capacity, match, matchDays, shiftId)
  $: selectedDates = selectedDays ? [...selectedDays] : []
  $: guestShifts = getGuestShifts(match.shifts, capacityGuest)
  $: availableShifts = guestShifts.filter(s =>
    selectedDates.some(d => shiftDayLookUp[d] && (shiftDayLookUp[d][s.shiftId]?.selected || shiftDayLookUp[d][s.shiftId]?.available))
  )
  $: everySelectedDayHasSingleShiftAndUser =
    schedulableUserIds.length === 1 &&
    match?.placeholderStudentCount === 0 &&
    (availableShifts.length === 1 || selectedDays?.every(d => shiftDayLookUp[d].shiftCount < 2))
  $: selectedDays, onSelectedDaysChanged()
  $: dateExceptions = match.capacity.dateExceptions
  $: if (dateExceptions) formattedDateExceptions = dateExceptions.map(de => dateService.datestamp(de))
  $: shiftDayExcluded = Object.entries(shiftDayLookUp)
    .filter(([, value]) => value.shiftCountAvailable === 0 && value.shiftCount === 0)
    .map(([key]) => dateService.datestamp(key))
  $: combinedExcludedDates = [...new Set([...formattedDateExceptions, ...shiftDayExcluded])]
  $: dayClasses = {
    ...Object.fromEntries(combinedExcludedDates.map(date => [date, 'excluded'])),
    ...Object.fromEntries(fullDateExcluded.map(date => [date, 'excluded'])),
    ...Object.fromEntries(shiftDayExcluded.map(date => [date, 'shift-violation'])),
  }

  // TODO: no need to load if restricted date range--only if expanded it (would also help when we compare a matchChange)
  // TODO: ideally, we'd get notified via websocket if slot capacity changed for this match's capacity so user can see bars changing in realtime, but not a big deal
  async function loadFilledCapacity() {
    if (capacity == null) return
    if (startDate == null || endDate == null) return
    if (!validator.date(startDate) || !validator.date(endDate) || !validator.dateSameOrBefore(startDate, endDate)) return
    const args = {
      capacityId: capacity.capacityId,
      guestOrgId: capacityGuest?.guestOrgId,
      startDate,
      endDate,
      excludeMatchId: matchId,
      shiftId: shiftId,
    }
    loading = true
    const response = await api.capacity.getAllocation(args, api.noMonitor)
    loading = false
    filledCapacityOthers = response.days
  }

  function comparableDate(date) {
    return dayjs(date).toDate().getTime()
  }

  function trimSelectedDays() {
    const trimmedDays = new Set()
    for (const date of selectedDays) {
      const withinStartEnd = comparableDate(date) >= comparableDate(match.startDate) && comparableDate(date) <= comparableDate(endDate)
      if (withinStartEnd) trimmedDays.add(date)
    }
    selectedDays = trimmedDays
  }

  function setFilledCapacity() {
    filledCapacity = addMatchToCapacityUsage(match, matchDays, filledCapacityOthers, matchPerStudent)
    calculateNewFullDates()
  }

  function shouldMarkDateAsFull(day, shiftId) {
    const bucket = filledCapacity && filledCapacity[day] ? filledCapacity[day][shiftId] : null
    const excludeFullDates = bucket?.maxMatchCountPerDay != null
    return excludeFullDates && bucket.filled === bucket.maxMatchCountPerDay
  }

  function calculateNewFullDates() {
    const newFullDates = []

    if (filledCapacity && shiftDayLookUp) {
      for (const day in filledCapacity) {
        for (const shiftId in shiftDayLookUp[day]) {
          if (filledCapacity[day] && filledCapacity[day][shiftId]) {
            const bucket = filledCapacity[day][shiftId]
            if (bucket.filled == bucket.maxMatchCountPerDay) {
              newFullDates.push(day)
            }
          }
        }
      }
    }

    fullDateExcluded = newFullDates
  }

  function onToggleShift(e) {
    const { shift, deselect, days, users } = e.detail
    const daysAvailable = getAvailableDays(shift, days, deselect)
    matchDays = toggleShift(matchDays, daysAvailable ? [...daysAvailable] : [], shift, users, deselect)
  }

  function onTogglePerson(e) {
    const { shift, person, deselect, days } = e.detail
    const daysAvailable = getAvailableDays(shift, days, deselect)
    matchDays = togglePerson(matchDays, daysAvailable, shift, person, deselect)
  }
  function onUpdateMatchDayConfigs(e) {
    const { shifts, days, matchDayConfig } = e.detail
    for (const shift of shifts) {
      const daysAvailable = getAvailableDays(shift, days, false)
      matchDayConfigs = updateMatchDayConfigs(
        match.matchId,
        matchDayConfigs ?? match.matchDayConfigs,
        daysAvailable,
        shift,
        matchDayConfig[shift.shiftId]
      )
    }
  }

  function getAvailableDays(shift, days, deselect) {
    // can always deselect
    if (deselect) return days
    // but can only select days that fall within the shift's schedule
    const availableDays = new Set([...days].filter(d => shiftDayLookUp[d][shift.shiftId].available))
    return availableDays
  }

  function isDateExcluded(date) {
    return combinedExcludedDates.includes(date) || (personaTypeIsSchool && fullDateExcluded.includes(date))
  }

  function isShiftSelectable(day, shiftId) {
    return shiftDayLookUp[day][shiftId]?.selected || shiftDayLookUp[day][shiftId]?.available
  }

  function onSelectedDaysChanged() {
    if (selectedDays == null || selectedDays.size === 0) return

    // make clear why nothing happened if they try to select a day where no shifts are available
    const everySelectedDayUnavailable = selectedDays.every(d =>
      availableShifts.every(s => !shiftDayLookUp[d][s.shiftId].available && !shiftDayLookUp[d][s.shiftId].selected)
    )
    noDaysAvailableInSelection = everySelectedDayUnavailable ? selectedDays : false
    if (noDaysAvailableInSelection) {
      showDetail = true
      selectedDays = new Set()
      return
    }

    // if only a single shift AND single scheduleable user available on the selected day(s), simply toggle it (no need to prompt the config modal)
    dayConfigOpen = !everySelectedDayHasSingleShiftAndUser
    if (everySelectedDayHasSingleShiftAndUser) {
      const deselect = selectedDays.every(
        d =>
          // one of the available shifts is selected
          availableShifts.some(s => shiftDayLookUp[d][s.shiftId].selected) ||
          // ignore days where all available shifts are unavailable
          availableShifts.every(s => !shiftDayLookUp[d][s.shiftId].available)
      )
      for (const shift of availableShifts) {
        const daysAvailable = getAvailableDays(shift, selectedDays, deselect)
        matchDays = toggleShift(matchDays, daysAvailable ? [...daysAvailable] : [], shift, schedulableUsers, deselect)
      }
      selectedDays = new Set()
    }
  }

  function setInitialStartEnd() {
    if (match.startDate) {
      start = dayjs(match.startDate)
      start = start.startOf('month')
      end = start.endOf('month')
    }
  }
</script>

<style>
  .top-controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
  }
</style>
