{#if !validator.empty(content)}
  <Icon name={icon} class="help-tip{className ? ` ${className}` : ''}" {focusable} title={content} tipOptions={options} />
{/if}
<div class="hide">
  <div bind:this={content} class={tipClass}>
    <slot />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import validator from 'services/validator.js'

  export let focusable = true
  export let tipOptions = {}
  let className = 'text-info'
  export { className as class }
  export let tipClass = null
  export let icon = 'help'

  let content = null
  $: options = {
    theme: 'help',
    interactive: true,
    maxWidth: 250,
    ...tipOptions,
  }
</script>
