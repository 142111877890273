<!--
todo
  - convert this to a wizard (when wizard has a better interface)
  - let schools schedule elective requests with clinical sites they don't have agreements with
  - maybe store selections in local storage so they can schedule similar rotations more quickly
  - allow adding/inviting students directly from this form by entering email+name
  - show freeze dates on capacities / disable selection when > freeze date
-->

{#if !hasCoreSchedulingFeature}
  <FeatureNeeded action="schedule core rotations" />
{:else if !hasSchedulingPermission}
  <div data-test="cant-schedule-warning" class="alert alert-warning">You do not have permission to schedule students.</div>
{:else}
  <Form on:submit={() => scheduleStudents(false)} bind:this={formComponent} bind:submitted noSubmitBtn>
    {#if !preselectedCapacity}
      <FormGroup
        valid={validator.int(inputOrgId)}
        validationMessage={personaTypeIsSchool ? 'Please select a health organization.' : 'Please select a school.'}
      >
        <label for={orgPickerId}>
          {orgPickerLabel}
          <RequiredMarker />
        </label>
        <!-- TODO(OMP): Removed respectOrgMatchPreferences to temporary ignore org match preferences -->
        <OrgPicker
          bind:this={orgPicker}
          name={orgPickerId}
          bind:value={input.orgId}
          bind:selected={selectedOrg}
          featureTypes={[personaTypeIsSchool ? FeatureType.HealthInstitution : FeatureType.TeachingInstitution]}
          hiddenFilters={[orgFilter, onlyShowRootOrgsFilter].filter(Boolean)}
          includeAgreementCount
          includeAddresses
          includeTags
          includeContactInfo
          includeDescription
          includeAncestorOrgIds
          includeActiveCapacityCount={!personaTypeIsSchool}
          includeStudentCount={!personaTypeIsSchool}
          modalTitle={orgPickerLabel}
          getOptionOverride={personaTypeIsSchool ? null : disableStudentlessOrgs}
          disabledMessage={orgPickerDisabledMessage}
          dataTest="org-picker"
        >
          <svelte:fragment slot="additional-filter">
            <InputCheckbox
              bind:checked={includeAllOpenOrgs}
              name="include-all-open-orgs"
              labelClass="m0 normal"
              class="flex-row flex-align-center g1"
            >
              {#if personaTypeIsSchool}
                Include health organizations offering opportunities to any school in the system
              {:else}
                Include schools that aren’t explicitly a school on your organization’s opportunities
              {/if}

              <Help slot="after-label">
                {#if personaTypeIsSchool}
                  Availability may be limited or rejected under the health organization’s discretion.
                {:else}
                  This will allow you to schedule into your organization’s opportunities configured with
                  <strong class="strongish">Open (any school)</strong> as a school.
                {/if}
              </Help>
            </InputCheckbox>

            {#if personaTypeIsSchool}
              <InputCheckbox bind:checked={onlyShowRootOrgs} name="only-show-root-orgs" labelClass="m0 normal" class="flex-row flex-align-center g1">
                Only show root organizations

                <Help slot="after-label" tipOptions={{ maxWidth: 380 }}>
                  Opportunities provided by descendant organizations/locations will still be selectable in the next step.
                </Help>
              </InputCheckbox>
            {/if}
          </svelte:fragment>
        </OrgPicker>
      </FormGroup>
    {/if}

    {#if !preselectedCapacity && selectedOrg}
      <FormGroup valid={validator.int(inputCapacityId)} validationMessage="Please select an opportunity.">
        <label for="capacityId">
          Select the opportunity
          <RequiredMarker />
        </label>
        <CapacityPicker
          bind:this={capacityPicker}
          bind:value={input.capacityId}
          bind:selected={selectedCapacity}
          filters={[...activeOpportunitiesFilters, allowsCoordinatorSchedulingFilter, capacityMatchCountsFilter].filter(Boolean)}
          excludedFilterTypes={[FilterType.CapacityAllowCoordinatorScheduling]}
          otherOrg={selectedOrg}
          capacityCount={selectedOrg?.capacityCount}
          includeShifts
        />
      </FormGroup>
    {/if}
    {#if selectedCapacity}
      {#if showStudentSchoolPicker}
        <!-- TODO(phase2-nursing): Student count does not take into account opportunity requirements (like student year or disciplines) -->
        <FormGroup
          valid={validator.min(selectedStudentsSchool?.studentCount, 1)}
          validationMessage={selectedStudentsSchool == null ? 'Please select a school.' : 'Please select a school with students.'}
        >
          <label for="capacity-guest-org-id">
            Select the school / program <RequiredMarker />
          </label>
          <div class="flex-row flex-align-center g1">
            <!-- TODO(OMP): Removed respectOrgMatchPreferences to temporary ignore org match preferences -->
            <OrgPicker
              name="capacity-guest-org-id"
              bind:this={studentSchoolPicker}
              bind:value={input.guestOrgId}
              bind:selected={selectedStudentsSchool}
              bind:options={studentSchools}
              bind:hasEverBeenFiltered={hasStudentSchoolPickerEverBeenFiltered}
              featureTypes={[FeatureType.TeachingInstitution]}
              class={showEmptySchoolMessage ? 'hide' : null}
              hiddenFilters={[guestOrgFilter].filter(Boolean)}
              optionMapper={disableStudentlessOrgs}
              disabledMessage={orgPickerDisabledMessage}
              capacityOwnerOrgId={selectedCapacity.orgId}
              includeAgreementCount
              includeActiveCapacityCount
              includeAncestorOrgIds
              includeStudentCount
              includeAddresses
              includeTags
              includeContactInfo
              includeDescription
              sortProperty={OrgListProperty.StudentCount}
              modalTitle="Select the school/program that has the students you’d like to schedule"
              dataTest="student-school-picker"
            />
            {#if showEmptySchoolMessage}
              <Alert type="danger">
                {#if studentSchools.length === 1}
                  The school <strong class="strongish">{studentSchools[0].name}</strong> doesn’t have any students; please select a different location
                  or another opportunity.
                {:else}
                  The following schools don’t have any students; please select a different location or another opportunity.
                  <ul>
                    {#each studentSchools as studentSchool}
                      <li>{studentSchool.name}</li>
                    {/each}
                  </ul>
                {/if}
              </Alert>
            {:else if showSchoolsFilteredMessage}
              {@const schoolsWithoutStudentsCount = studentSchools.filter(s => s.studentCount === 0).length}
              <Alert type="warning">
                {#if schoolsWithoutStudentsCount === 1}
                  There’s another school/program
                {:else}
                  There are other schools/programs
                {/if}
                with access to this opportunity, however this is the only one with students.
              </Alert>
            {:else if studentSchools == null}
              <div class="text-center mt1">
                <Spinner x3 class="m2" />
              </div>
            {/if}
            {#if selectedStudentsSchool && guestScheduleNotStartedMessage}
              <Alert type="danger">{guestScheduleNotStartedMessage}</Alert>
            {/if}
          </div>
        </FormGroup>
      {/if}
      {#if selectedStudentsSchool && !showEmptySchoolMessage}
        {#if !hideCapacityOverview}
          <CapacityOverview capacity={selectedCapacity} {capacityGuest} showName />
        {/if}
        {#if !guestScheduleNotStartedMessage || (guestScheduleNotStartedMessage && !personaTypeIsSchool)}
          {#if personaTypeIsSchool && hasCoursesFeature}
            {@const noCourseLabel = `No course associated with ${thisRotationOrTheseRotations}`}
            <FormGroup>
              <label for="preceptor-ids">
                Should the {rotationOrRotations} be associated with a course?
              </label>
              <Help>This can be modified afterward too.</Help>
              <CoursePicker
                bind:value={input.courseId}
                bind:selectedCourse
                placeholder={noCourseLabel}
                emptyPickerText={noCourseLabel}
                allowSelectNull
              />
            </FormGroup>
          {/if}

          {#if personaTypeIsSchool && !selectedStudents?.length && !inputPlaceholderStudentCount && guestScheduledStudentCount >= guestMaxStudentCount}
            <Alert type="danger">
              Maximum student limit reached for this opportunity. Here are some options you could try:
              <ul class="m0 pl2">
                <!-- Would be better to check if the user has access to more than one CapacityGuest, but this is good enough for now. -->
                {#if studentSchools.length > 1}
                  <li>Select a different school / program</li>
                {/if}
                <li>Select a different opportunity</li>
                <li>Remove students from existing rotations</li>
                {#if allowsPlaceholderStudents}
                  <li>Remove placeholder students from existing rotations</li>
                {/if}
                <li>Close existing rotations</li>
              </ul>
            </Alert>
          {:else if canSelectUsersAndDates}
            <!-- choose students, filtered to those who are eligible for the chosen capacity -->
            {@const validationMessage =
              $studentCount + inputPlaceholderStudentCount === 0
                ? `You must select at least one student${allowsPlaceholderStudents ? ' or placeholder student' : ''}.`
                : $studentCount + inputPlaceholderStudentCount === 1
                  ? null
                  : input.createGroupRotation && !selectedCapacity.allowGroups
                    ? 'The selected opportunity doesn’t allow groups.'
                    : null}
            <FormGroup valid={validationMessage == null} {validationMessage}>
              <Collapsible light open>
                <span slot="label" class="small em">
                  <div class="flex-row g05">
                    {#if inputPlaceholderStudentCount > 0}
                      <div class="flex-column">
                        {pluralCount('placeholder', inputPlaceholderStudentCount)}
                        <PlaceholderStudentProfilePic xs value={inputPlaceholderStudentCount} />
                      </div>
                    {/if}
                    <div class="flex-column">
                      {pluralCount('student', selectedStudents)}
                      {#if selectedStudents?.length}
                        <div class="flex-row g05">
                          <FriendlyList class="flex-row" max={10} items={selectedStudents} let:item={user}>
                            <ProfilePic src={user.profilePicture} name={user.name} id={user.userId} initials={user.initials} small class="mr1" />
                          </FriendlyList>
                        </div>
                      {/if}
                    </div>
                    <div class="g05">
                      {#if personaTypeIsSchool}
                        {pluralCount('school faculty', selectedSchoolFaculty?.length)}
                        {#if selectedSchoolFaculty?.length}
                          <div class="flex-row g05">
                            <FriendlyList class="flex-row" max={10} items={selectedSchoolFaculty} let:item={user}>
                              <ProfilePic src={user.profilePicture} name={user.name} id={user.userId} initials={user.initials} small class="mr1" />
                            </FriendlyList>
                          </div>
                        {/if}
                      {:else}
                        {pluralCount('staff', assignedPreceptors?.length)}
                        {#if assignedPreceptors?.length}
                          <div class="flex-row g05">
                            <FriendlyList class="flex-row" max={10} items={assignedPreceptors} let:item={user}>
                              <ProfilePic name={user.name} id={user.userId} initials={user.initials} small class="mr1" />
                            </FriendlyList>
                          </div>
                        {/if}
                      {/if}
                    </div>
                  </div>
                </span>

                <Collapsible name="match-students" open>
                  <span slot="label">
                    <Icon name="graduation-cap" class="color-text-teal" />
                    <span>Select students to schedule</span>
                    <RequiredMarker />
                    {#if guestMaxStudentCount > 0}
                      {@const maxCount = guestMaxStudentCount - guestScheduledStudentCount}
                      <Badge count={selectedStudents?.length + inputPlaceholderStudentCount} max={Math.max(0, maxCount)} min={0} />
                    {:else}
                      <Badge min={0} icon="infinity" label="Unlimited" />
                    {/if}
                  </span>
                  <div>
                    {#if selectedCourse}
                      <HelpBlock>
                        You can select a group of students from the course <strong>{selectedCourse.name}</strong> or select individual students.
                      </HelpBlock>
                      <div class="mb1">
                        <CourseGroupPicker course={selectedCourse} onSelect={onCourseGroupChanged} selectedStudentUserIds={inputStudentIds} />
                      </div>
                    {/if}
                  </div>
                  <div class="flex-row">
                    {#if inputPlaceholderStudentCount}
                      <PlaceholderStudentCard bind:value={inputPlaceholderStudentCount} onDelete={() => (input.placeholderStudentCount = 0)} />
                    {/if}
                    {#if selectedStudents?.length}
                      <div class="flex-column">
                        {#each selectedStudents as student, i}
                          <StudentCard {student} onDelete={onRemoveStudent} />
                        {/each}
                      </div>
                    {/if}
                  </div>
                  <div class="flex-row g05">
                    {#if allowsPlaceholderStudents}
                      <PlaceholderStudentsModal
                        bind:value={input.placeholderStudentCount}
                        bind:matchDayConfigs={inputMatchDayConfigs}
                        shifts={schedulePickerMatch?.shifts}
                        maxStudentCount={personaTypeIsSchool ? Math.min(guestMaxStudentCount, guestRemainingStudentCount) : null}
                        atMaxStudents={personaTypeIsSchool &&
                          guestScheduledStudentCount + selectedStudents?.length + inputPlaceholderStudentCount >= guestMaxStudentCount}
                      />
                    {/if}
                    <MatchStudentPicker
                      bind:this={studentPicker}
                      bind:value={input.studentIds}
                      bind:selected={selectedStudents}
                      filters={studentPickerCourseFilter ? [studentPickerCourseFilter] : []}
                      multiple
                      capacityId={selectedCapacity.capacityId}
                      capacityStudentYears={selectedCapacity.studentYears}
                      disciplines={selectedCapacity.disciplines}
                      orgId={schoolOrgId}
                      allowStudentYearOverride={$user.isHealthCoordinator}
                      scheduledStudentCount={guestScheduledStudentCount}
                      maxStudentCount={personaTypeIsSchool ? guestMaxStudentCount : null}
                      atMaxStudents={personaTypeIsSchool &&
                        guestScheduledStudentCount + selectedStudents?.length + inputPlaceholderStudentCount >= guestMaxStudentCount}
                      modalTitle="Which student(s) would you like to schedule in this rotation?"
                      on:changed={setPreceptorIdsDefault}
                      hideSelectAll
                      listStudentsFromOrgId={selectedStudentsSchool?.orgId}
                    />
                  </div>
                </Collapsible>
                {#if personaTypeIsSchool}
                  <Collapsible open>
                    <span slot="label">
                      <Icon name="school" class="color-text-blue" /> Add school faculty
                      <Badge count={selectedSchoolFaculty?.length} min={0} />
                    </span>
                    {#if selectedSchoolFaculty?.length}
                      <div class="flex-row flex-wrap">
                        {#each selectedSchoolFaculty as faculty}
                          <MatchStaffCard class="card-small" user={faculty} onDelete={onRemoveFaculty} label="faculty" />
                        {/each}
                      </div>
                    {/if}
                    <MatchStaffPicker
                      bind:this={schoolFacultyPicker}
                      bind:value={input.schoolFacultyIds}
                      bind:allStaff={allFaculty}
                      bind:selected={selectedSchoolFaculty}
                      dataTest="school-faculty-ids"
                      orgId={schoolOrgId}
                      multiple
                      capacityId={selectedCapacity.capacityId}
                      modalTitle="Which school faculty members would you like to add to this rotation?"
                      hideSelectAll
                    />
                  </Collapsible>
                {:else}
                  <Collapsible name="staff-collapsible" open>
                    <span slot="label">
                      <Icon name="school" /> Add staff
                      <Badge count={assignedPreceptors?.length} min={0} />
                    </span>

                    {#if autoAssignedPreceptors?.length}
                      <Collapsible>
                        <span slot="label">
                          <Icon name="school" /> Auto-assigned {pluralCount('preceptor', autoAssignedPreceptors?.length, 'omitNumber')}
                          <Badge count={autoAssignedPreceptors} />
                          <Help>
                            Based on their roles, they’ll be automatically added to the created {rotationOrRotations}.
                          </Help>
                        </span>
                        <div class="flex-column g05">
                          <FriendlyList items={autoAssignedPreceptors} let:item={user} allowShowFewer>
                            <div class="flex-row flex-align-center">
                              <ProfilePic src={user.profilePicture} name={user.name} id={user.userId} initials={user.initials} medium class="mr1" />
                              {user.name}
                            </div>
                          </FriendlyList>
                        </div>
                      </Collapsible>
                    {/if}
                    <div class="flex-row flex-wrap">
                      {#each selectedPreceptors as preceptor, i}
                        <MatchStaffCard user={preceptor} onDelete={onRemoveStaff} label="staff" />
                      {/each}
                    </div>
                    <MatchStaffPicker
                      bind:value={input.preceptorIds}
                      bind:allStaff={allPreceptors}
                      bind:selected={selectedPreceptors}
                      dataTest="preceptor-ids"
                      orgId={$persona.orgId}
                      multiple
                      capacityId={selectedCapacity.capacityId}
                      modalTitle="Which preceptors would you like to add to this rotation?"
                      hideSelectAll
                      personaTypeisHealth
                    />
                  </Collapsible>
                {/if}
              </Collapsible>
            </FormGroup>

            <FormGroup valid={validator.date(inputStartDate) && validator.date(inputEndDate)}>
              <label class="mb0" for="match-dates-start">
                Choose start and end dates
                <RequiredMarker />
              </label>
              <MatchDatePicker
                isCreating
                bind:startDate={input.startDate}
                bind:endDate={input.endDate}
                bind:matchDays={input.matchDays}
                capacity={selectedCapacity}
                {submitted}
                match={schedulePickerMatch}
              />
            </FormGroup>

            {#if hasStartAndEndDate}
              {#if $allowGroups && totalStudentCount > 1}
                <FormGroup>
                  <label for="display-name">Optionally enter a group name</label>
                  <Help>A helpful name for the group - displayed on dashboards and in notifications</Help>
                  <InputText name="display-name" bind:value={input.displayName} />
                </FormGroup>
              {/if}

              <FormGroup>
                <h3 class="mb0">Rotation schedule</h3>
                <HelpBlock>
                  {#if selectedCapacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.MultipleAvailableShifts}
                    {#if allowCoordinatorScheduling}
                      Optionally set specific schedule(s) for your rotation by clicking on the calendar or using the schedule button. If you don’t set
                      a schedule, your rotation will be assumed to be daily.
                    {:else}
                      This opportunity doesn’t allow for requesting specific availability windows, only start and end dates.
                      <strong class="strongish">{selectedOrg?.name || selectedStudentsSchool?.name}</strong> will configure schedule details as necessary.
                    {/if}
                  {:else if selectedCapacity.detailedScheduleCanBeSetBy >= DetailedScheduleCanBeSetBy.AnyCoordinator}
                    Select an availability window you’re interested in creating the rotation for in step 1, and optionally set a detailed schedule for
                    it in step 2. A detailed schedule can always be set later.
                  {:else}
                    Select one availability window to work with. <strong class="strongish">{selectedOrg.name || selectedStudentsSchool?.name}</strong>
                    doesn’t allow more detailed schedules, and they will configure schedules as necessary.
                  {/if}
                </HelpBlock>
              </FormGroup>

              {#if selectedCapacity.detailedScheduleCanBeSetBy >= DetailedScheduleCanBeSetBy.AnyCoordinator && selectedCapacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.SingleAvailableShift}
                <FormGroup>
                  <Collapsible
                    iconClass="color-text-teal"
                    label="shifts"
                    name="capacity-shifts"
                    open={hasStartAndEndDate}
                    disabled={!hasStartAndEndDate}
                  >
                    <h3 slot="label" class="mb0">Step 1: select an availability window</h3>
                    <CapacityShiftGrid bind:selectedShiftId={input.shiftId} capacity={selectedCapacity} {capacityGuest} />
                  </Collapsible>
                </FormGroup>
              {:else if selectedCapacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.SingleAvailableShift}
                <FormGroup>
                  <CapacityShiftGrid bind:selectedShiftId={input.shiftId} capacity={selectedCapacity} {capacityGuest} />
                </FormGroup>
              {/if}

              {#if allowCoordinatorScheduling}
                <FormGroup>
                  <div bind:this={schedulePickerElem}>
                    {#if personaTypeIsSchool && selectedCapacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.SingleAvailableShift}
                      <Collapsible iconClass="color-text-teal" disabled={inputShiftId == null} open={inputShiftId != null}>
                        <svelte:fragment slot="label">
                          <div class="flex-row mt1">
                            <h3 class="mb0">Step 2: set schedule</h3>
                            {#if !inputShiftId}
                              <HelpBlock class="ml1">You must select an availability window first</HelpBlock>
                            {/if}
                          </div>
                        </svelte:fragment>

                        <SchedulePicker
                          capacity={selectedCapacity}
                          match={schedulePickerMatch}
                          bind:matchDayConfigs={inputMatchDayConfigs}
                          bind:matchDays={input.matchDays}
                          matchPerStudent={!selectedCapacity.allowGroups}
                          shiftId={inputShiftId}
                          {capacityGuest}
                          showDetail
                        />
                      </Collapsible>
                    {:else}
                      <SchedulePicker
                        capacity={selectedCapacity}
                        match={schedulePickerMatch}
                        bind:matchDayConfigs={inputMatchDayConfigs}
                        bind:matchDays={input.matchDays}
                        matchPerStudent={!selectedCapacity.allowGroups}
                        shiftId={inputShiftId}
                        {capacityGuest}
                        showDetail
                      />
                    {/if}
                  </div>
                </FormGroup>
              {/if}

              {#if hasStartAndEndDate}
                <FormGroup>
                  <label for="additional-comments">Optionally enter additional comments / details</label>
                  <InputTextarea name="additional-comments" bind:value={input.additionalComments} />
                </FormGroup>
              {/if}
            {/if}
          {/if}
        {/if}
      {/if}
    {/if}

    <!--
        TODO(custom-tags):
          when creating rotations - we should probably allow either side to select from their own AND the other side's custom tags ()
          get custom tags from api for both clinical site and school
          only show this field if there are custom tags to choose from

          show 2 lists of tags in some kind of multi selectable ux, or just checkboxgroups

          health             school
          ------             ------
          [ ] tag1           [ ] tag4
          [ ] tag2           [ ] tag5
          [ ] tag3           [ ] tag6
          {#if personaTypeIsSchool}
          <FormGroup>
            <label>Optionally add custom tags</label>
            <InputSelect name="custom-tag-ids" />
            <HelpBlock>
              These are an optional feature that allows you to assign your staff to, or filter to, any subset of rotations. For example, you might tag
              all rotations tied to a specific course or department.
            </HelpBlock>
          </FormGroup>
          {/if}
        -->
  </Form>
  <!-- {/if} -->
{/if}

<script context="module">
  import { writable } from 'svelte/store'

  export function buildStore() {
    return {
      submitButtonLabel: writable('Schedule students'),
      loading: writable(false),
      allowGroups: writable(false),
      matchCount: writable(0),
      studentCount: writable(0),
      shouldClose: writable(false),
      datePickerIsLastVisibleInput: writable(false),
    }
  }
</script>

<script>
  import { activeOpportunitiesFilters } from 'pages/authorized/org/OpportunitiesView.svelte'
  // import { buildFilterTypes } from 'components/CapacityFilters.svelte'
  import { tick } from 'svelte'
  import {
    MatchStatus,
    MatchRole,
    PersonaType,
    FeatureType,
    FilterType,
    OrgListProperty,
    Permission,
    // SimpleMatchCountsOption,
    StaffRole,
    ToManyComparison,
    RequestAvailabilityPolicy,
    DetailedScheduleCanBeSetBy,
  } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import CapacityOverview from 'pages/authorized/org/Capacity.Overview.svelte'
  import CapacityPicker from 'components/fields/CapacityPicker.svelte'
  import CapacityShiftGrid from 'components/CapacityShiftGrid.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import confirms from 'stores/confirms.js'
  import CourseGroupPicker from 'components/CourseGroupPicker.svelte'
  import CoursePicker from 'components/CoursePicker.svelte'
  import FeatureNeeded from 'components/FeatureNeeded.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import getMatchController from 'services/match-controller.js'
  import Help from 'components/Help.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import MatchDatePicker from 'components/MatchDatePicker.svelte'
  import MatchStaffCard from 'components/MatchStaffCard.svelte'
  import MatchStaffPicker from 'components/fields/MatchStaffPicker.svelte'
  import MatchStudentPicker from 'components/MatchStudentPicker.svelte'
  import OrgPicker from 'components/OrgPicker.svelte'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import personaService from 'services/persona-service.js'
  import PlaceholderStudentCard from 'components/PlaceholderStudentCard.svelte'
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'
  import PlaceholderStudentsModal from 'components/PlaceholderStudentsModal.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SchedulePicker from 'components/fields/SchedulePicker.svelte'
  import scrollToEl from 'services/scroll-service.js'
  import Spinner from 'components/Spinner.svelte'
  import StudentCard from 'components/StudentCard.svelte'
  import toaster from 'services/toaster.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'

  export let submitted = false
  export let preselectedCapacity = null
  export let preselectedCapacityGuest = null
  export let actualCapacityGuest = null // For upward binding to parent so it can know the user picked an org that doesn't match the guest they picked
  export let hideCapacityOverview = false
  export let store
  export let onStudentsScheduled = _.noop

  const loading = store.loading
  const submitButtonLabel = store.submitButtonLabel
  const allowGroups = store.allowGroups
  const matchCount = store.matchCount
  const studentCount = store.studentCount
  const datePickerIsLastVisibleInput = store.datePickerIsLastVisibleInput

  const form = 'ScheduleStudentsForm'
  // const filterTypes = buildFilterTypes()
  const allowsCoordinatorSchedulingFilter = {
    type: FilterType.CapacityAllowCoordinatorScheduling,
    config: {
      allowCoordinatorScheduling: true,
    },
  }

  // Temporarily remove the "Has available rotations" filter because it's performing poorly.
  const capacityMatchCountsFilter = null // {
  //   type: FilterType.CapacityMatchCounts,
  //   config: {
  //     ...filterTypes[FilterType.CapacityMatchCounts].create(filterTypes[FilterType.CapacityMatchCounts]),
  //     simpleOption: SimpleMatchCountsOption.HasAvailableMatches,
  //   },
  // }
  const input = {}
  let inputInitial = {}
  let ignoreResets = false

  // Components & elements
  let formComponent
  let orgPicker
  let capacityPicker
  let studentPicker
  let schoolFacultyPicker
  let studentSchoolPicker
  let showStudentSchoolPicker
  let hasStudentSchoolPickerEverBeenFiltered
  let schedulePickerElem

  // Default to null so === can be used
  let lastOrgId = null
  let lastCapacityId = null
  let lastGuestOrgId = null

  let selectedStudents
  let selectedStudentsSchool
  let selectedSchoolFaculty

  let selectedOrg
  let selectedCourse
  let selectedCapacity
  let studentSchools
  let guestScheduleNotStartedMessage
  let autoAssignedPreceptors
  let autoAssignedPreceptorIds
  let guestOrgFilter
  let includeAllOpenOrgs = false
  let onlyShowRootOrgs = true

  let assignedPreceptors

  let allFaculty
  let allPreceptors

  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  // TODO(phase2-nursing): Validate if the user has access to the selected opportunity
  $: hasSchedulingPermission = personaService.hasPermissionAnywhere(Permission.AddMatchesApi)
  $: hasCoursesFeature = personaService.canUseAnyFeatureType(FeatureType.Courses)

  $: personaTypeIsSchool = $persona.personaType === PersonaType.SchoolStaff
  $: personaFiltersOrgId = $personaFilters.orgId
  $: personaFiltersOrgId, resetInputToCheckpoint('empty', { willBeInitial: true })
  $: unsavedForms.formHasChanges(form, inputInitial, input)

  $: orgPickerLabel =
    personaTypeIsSchool && !preselectedCapacity
      ? 'Select the health organization you’d like to schedule with'
      : 'Select the school you’d like to schedule with'
  $: orgPickerDisabledMessage = personaTypeIsSchool ? null : 'You cannot select a school with 0 students.'
  $: orgPickerId = personaTypeIsSchool ? 'host-org-id' : 'guest-org-id'
  $: if (preselectedCapacity) selectedCapacity = preselectedCapacity
  $: allowCoordinatorScheduling =
    selectedCapacity &&
    ((personaTypeIsSchool && selectedCapacity.detailedScheduleCanBeSetBy >= DetailedScheduleCanBeSetBy.AnyCoordinator) ||
      (!personaTypeIsSchool && selectedCapacity.detailedScheduleCanBeSetBy >= DetailedScheduleCanBeSetBy.ClinicalSiteCoordinator))

  $: orgFilter = personaTypeIsSchool
    ? {
        type: FilterType.ConnectedHostOrg,
        config: { includeOrgsWithOpenCapacities: includeAllOpenOrgs },
      }
    : {
        type: FilterType.ConnectedGuestOrg,
        config: { includeAllPotentialGuestOrgs: includeAllOpenOrgs },
      }
  $: onlyShowRootOrgsFilter = personaTypeIsSchool && onlyShowRootOrgs ? { type: FilterType.HasParentOrg, config: { parentOrgIds: [null] } } : null

  // Cached input values for less reactivity
  $: inputOrgId = input.orgId
  $: inputCapacityId = input.capacityId
  $: inputGuestOrgId = input.guestOrgId
  $: inputStudentIds = input.studentIds ?? []
  $: inputSchoolFacultyIds = input.schoolFacultyIds
  $: inputPreceptorIds = input.preceptorIds
  $: inputCourseId = input.courseId
  $: inputStartDate = input.startDate
  $: inputEndDate = input.endDate
  $: hasStartAndEndDate = inputStartDate && inputEndDate
  $: inputShiftId = input.shiftId
  $: inputMatchDayConfigs = input.matchDayConfigs ?? []
  $: inputPlaceholderStudentCount = input.placeholderStudentCount

  $: $allowGroups = selectedCapacity?.allowGroups
  $: $studentCount = inputStudentIds.length
  $: $matchCount = $allowGroups ? ($studentCount ? 1 : 0) : $studentCount
  $: totalStudentCount = $studentCount + inputPlaceholderStudentCount
  $: $submitButtonLabel =
    totalStudentCount > 1
      ? $allowGroups
        ? `Create a group rotation with ${totalStudentCount} students`
        : `Create ${totalStudentCount} individual rotations`
      : 'Create rotation'

  // expectedMatchCount is used differently than $matchCount -- it's is more about after everything is said and done,
  // whereas $matchCount is more about how the form is currently filled in so the buttons are more accurate.
  $: expectedMatchCount = $allowGroups ? 1 : input.studentIds?.length ?? Infinity
  $: rotationOrRotations = pluralCount('rotation', expectedMatchCount, 'omitNumber')
  $: thisRotationOrTheseRotations = expectedMatchCount > 1 ? 'these rotations' : 'this rotation'

  $: selectedPreceptors = getSelectedUsers(allPreceptors, inputPreceptorIds)
  $: selectedSchoolFaculty = getSelectedUsers(allFaculty, inputSchoolFacultyIds)

  // consider warning when logged in as school coordinator and the selected capacity's agreement's school
  // is different than the logged in school coordinator's persona's org.
  $: selectedOrg, onSelectedOrgChanged()
  $: selectedCapacity, onSelectedCapacityChanged()
  $: schoolsWithStudents = studentSchools?.filter(s => s.studentCount > 0) || []
  $: if (selectedCapacity == null) {
    selectedStudentsSchool = null
  } else if (studentSchoolPicker && !hasStudentSchoolPickerEverBeenFiltered) {
    if (schoolsWithStudents.length === 1) {
      selectedStudentsSchool = schoolsWithStudents[0]
      input.guestOrgId = schoolsWithStudents[0].orgId
    } else if (!personaTypeIsSchool && !preselectedCapacity && !preselectedCapacityGuest) {
      selectedStudentsSchool = selectedOrg
      input.guestOrgId = selectedOrg.orgId
    }
  }
  $: showEmptySchoolMessage = studentSchools?.length && schoolsWithStudents.length === 0 && !hasStudentSchoolPickerEverBeenFiltered
  $: showSchoolsFilteredMessage =
    studentSchools?.length > 1 && schoolsWithStudents.length === 1 && selectedStudentsSchool && !hasStudentSchoolPickerEverBeenFiltered
  $: hostOrgId = personaTypeIsSchool ? selectedCapacity?.orgId ?? inputOrgId : personaFiltersOrgId
  $: schoolOrgId = selectedStudentsSchool?.orgId ?? (personaTypeIsSchool ? personaFiltersOrgId : null)
  $: capacityGuest = getCapacityGuest(selectedCapacity, selectedStudentsSchool)
  $: allowsPlaceholderStudents = capacityGuest?.allowsAddingPlaceholderStudents
  $: actualCapacityGuest = capacityGuest
  $: guestMaxStudentCount = capacityGuest?.maxStudents
  $: guestRemainingStudentCount = capacityGuest?.studentCountRemaining
  $: guestScheduledStudentCount = capacityGuest?.studentCount

  $: canSelectUsersAndDates = selectedCapacity?.guests?.length && capacityGuest != null && schoolOrgId
  $: $datePickerIsLastVisibleInput = canSelectUsersAndDates && !hasStartAndEndDate

  $: selectedStudentsSchool, onSelectedStudentsSchoolChanged()

  $: selectedCourse, onCourseChanged()
  $: selectedPreceptors, onPreceptorsChanged()
  $: selectedPreceptors, getAssignedPreceptors(selectedPreceptors, autoAssignedPreceptors)

  $: schedulePickerMatch = {
    status: personaTypeIsSchool ? MatchStatus.PendingClinicalSite : MatchStatus.Onboarding,
    startDate: inputStartDate,
    endDate: inputEndDate,
    capacity: selectedCapacity,
    shifts: selectedCapacity?.shifts ?? [],
    matchUsers: [
      ...(selectedStudents?.map(u => matchUserFrom(u, MatchRole.Student)) ?? []),
      ...(selectedSchoolFaculty?.map(u => matchUserFrom(u, MatchRole.SchoolFaculty)) ?? []),
      ...(selectedPreceptors.map(u => matchUserFrom(u, MatchRole.Preceptor)) ?? []),
    ],
    otherMatchSchedules: [],
    placeholderStudentCount: inputPlaceholderStudentCount,
  }

  $: studentPickerCourseFilter = inputCourseId
    ? {
        type: FilterType.Course,
        config: {
          courseIds: [inputCourseId],
          comparison: ToManyComparison.AnyOf,
        },
      }
    : null

  $: if (inputShiftId) scrollToEl(schedulePickerElem)
  $: if (selectedStudentsSchool) setGuestScheduleNotStartedMessage()
  $: if (guestOrgFilter) showStudentSchoolPicker = true

  function buildDefaultInput() {
    const guestOrgId = preselectedCapacityGuest
      ? personaTypeIsSchool && preselectedCapacityGuest.guestOrgDescendantIds?.includes(personaFiltersOrgId)
        ? personaFiltersOrgId
        : preselectedCapacityGuest.guestOrgId
      : null

    return {
      orgId: null,
      capacityId: preselectedCapacity?.capacityId ?? null,
      guestOrgId,
      shiftId: null,
      placeholderStudentCount: 0,
      studentIds: [],
      courseId: null,
      schoolFacultyIds: [],
      preceptorIds: [],
      startDate: null,
      endDate: null,
      displayName: '',
      matchDays: [],
      matchDayConfigs: [],
      additionalComments: '',
    }
  }

  async function resetInputToCheckpoint(checkpoint, { willBeInitial } = {}) {
    // When we're resetting things, a cascade of resets will happen, so let's
    // ignore them to get a bit of performance and hopefully prevent potential bugs.
    if (ignoreResets) return
    ignoreResets = true
    // When we call `clear()` on the pickers, sometimes the picker variable will be not null
    // but the exported clear method will not be on it, just the props. I believe it's because
    // the components are wrapped in `{#if}` blocks and Svelte keeps it partially hydrated.
    // So we need to do ?.clear?.() to avoid errors.
    const resetInput = buildDefaultInput()
    /* eslint-disable no-fallthrough */
    switch (checkpoint) {
      case 'empty':
        // In these next 3 cases, we need to reset the cached inputXyz values
        // because the event callbacks actually happen before this function finishes
        // so the if (inputXyz === lastXyz) return; check will fail.
        // We could also set the lastXyz stuff here but there's no point when the
        // event callbacks would otherwise do it; we'd just be shipping more code for no reason.
        orgPicker?.clear?.()
        inputOrgId = resetInput.orgId
        input.orgId = inputOrgId
        if (inputOrgId == null) selectedOrg = null
      case 'org':
        capacityPicker?.clear?.()
        // If they have a preselected capacity, this is a no-op.
        inputCapacityId = resetInput.capacityId
        input.capacityId = inputCapacityId
        if (inputCapacityId == null) selectedCapacity = null
      case 'capacity':
        studentSchoolPicker?.clear?.()
        // If they have a preselected guest, this is a no-op.
        inputGuestOrgId = resetInput.guestOrgId
        input.guestOrgId = inputGuestOrgId
      case 'school':
        input.courseId = resetInput.courseId
        if (resetInput.courseId == null) selectedCourse = null
      case 'course':
        studentPicker?.clear?.()
        input.placeholderStudentCount = resetInput.placeholderStudentCount
        input.studentIds = resetInput.studentIds
      case 'students':
        schoolFacultyPicker?.clear?.()
        input.schoolFacultyIds = resetInput.schoolFacultyIds
        input.preceptorIds = resetInput.preceptorIds
      case 'non-students':
        input.startDate = resetInput.startDate
        input.endDate = resetInput.endDate
        input.shiftId = resetInput.shiftId
      case 'dates':
        input.displayName = resetInput.displayName
      case 'display-name':
        input.matchDays = resetInput.matchDays
        input.matchDayConfigs = resetInput.matchDayConfigs
      case 'match-days':
        input.additionalComments = resetInput.additionalComments
      case 'additional-comments':
    }
    /* eslint-enable no-fallthrough */
    if (willBeInitial) inputInitial = _.cloneDeep(input)
    formComponent?.reset()
    await tick()
    ignoreResets = false
  }

  function getSelectedUsers(list, selectedIds) {
    return list && selectedIds ? list.filter(u => selectedIds.includes(u.userId)) : []
  }

  function matchUserFrom(user, matchRole) {
    return { ...user, matchRole }
  }

  export function onSubmit() {
    formComponent?.onSubmit()
  }

  async function scheduleStudents(skipMatchUsageValidation = false) {
    const body = _.cloneDeep(input)
    if (personaTypeIsSchool) body.preceptorIds = null
    body.createGroupRotation = $allowGroups
    body.matchDays = body.matchDays.filter(md => {
      // removing schedule selection from another shift
      if (body.shiftId && md.shiftId && md.shiftId !== body.shiftId) return false
      // only send along days within the start/end date range; they may have changed the range as they were selecting days
      // but we don't bother clearing them as they do that because it'd be kinda annoying
      // The API errors about days outside range, so that people who use our API will clearly know why a day doesn't get saved
      const mdDate = dayjs(md.date)
      return mdDate.isSameOrAfter(inputStartDate) && mdDate.isSameOrBefore(inputEndDate)
    })
    body.matchDayConfigs = body.matchDayConfigs.filter(mdc => {
      // removing match day configs from another shift
      if (body.shiftId && mdc.shiftId && body.shiftId !== mdc.shiftId) return false

      // removing days without phs or selected users
      if (mdc.placeholderStudentCount == 0 && !body.matchDays.some(md => md.shiftId === mdc.shiftId && md.date == mdc.date)) return false

      // removing days outside selected range
      const mdcDate = dayjs(mdc.date)
      if (!mdcDate.isSameOrAfter(inputStartDate) || !mdcDate.isSameOrBefore(inputEndDate)) return false

      return true
    })

    body.guestOrgId = !body.studentIds?.length && body.placeholderStudentCount ? schoolOrgId : null

    if (skipMatchUsageValidation) body.skipMatchUsageValidation = true

    // TODO(OMP): Temporary ignore org match preferences
    body.ignoreOrgMatchPreferences = true
    $loading = true
    try {
      const controller = getMatchController()
      const matchIds = await controller.addRotation(body, {
        monitor: false,
        canHandleRequestError: (response, content) => {
          return !!content.confirmationMessage
        },
      })
      toaster.toast({
        message: 'Rotation successfully created',
        type: 'success',
        icon: 'check',
        // clicking toast goes to one of the matches they added. later on, maybe show a dismissable section that links to all the rotations they've scheduled so far or something.
        url: `/dashboard/rotation-list/matches/${matchIds[0]}`,
      })
      await resetInputToCheckpoint('org', { willBeInitial: true })
      if (preselectedCapacity) unsavedForms.del(form)
      onStudentsScheduled()
    } catch (error) {
      const message = error?.content?.confirmationMessage
      if (!message) throw error
      confirms.add({
        title: 'Confirmation needed',
        message,
        confirmLabel: `${$submitButtonLabel} anyway`,
        onConfirm: () => {
          scheduleStudents(true)
        },
      })
    } finally {
      $loading = false
    }
  }

  async function onSelectedOrgChanged() {
    if (inputOrgId === lastOrgId) return
    lastOrgId = inputOrgId
    await resetInputToCheckpoint('org', { willBeInitial: false })
  }

  function buildAncestorOrgIdsFilter(ancestorOrgIds) {
    return { type: FilterType.HasAncestorOrg, config: { ancestorOrgIds } }
  }

  async function onSelectedCapacityChanged() {
    if (inputCapacityId === lastCapacityId) return
    lastCapacityId = inputCapacityId
    if (selectedCapacity == null) return
    await resetInputToCheckpoint('capacity', { willBeInitial: false })
    // After awaiting, selectedCapacity could now be null, so check it again.
    if (selectedCapacity == null) return
    showStudentSchoolPicker = false

    if (preselectedCapacityGuest?.guestOrgId) {
      if (personaTypeIsSchool) {
        const ancestorOrgIds = preselectedCapacityGuest.guestOrgDescendantIds?.includes(personaFiltersOrgId)
          ? [personaFiltersOrgId]
          : [preselectedCapacityGuest.guestOrgId]
        guestOrgFilter = buildAncestorOrgIdsFilter(ancestorOrgIds)
      } else {
        guestOrgFilter = buildAncestorOrgIdsFilter([preselectedCapacityGuest.guestOrgId])
      }
      return
    }

    if (personaTypeIsSchool) {
      if (selectedCapacity.guests.some(cg => !cg.guestOrgId || cg.guestOrgId === personaFiltersOrgId)) {
        // the opportunity is open to any school or the selected personaFilters is a guest, so we list selected location descendants
        guestOrgFilter = buildAncestorOrgIdsFilter([personaFiltersOrgId])
        return
      } else {
        const ancestorOrgIds = []
        for (const guest of selectedCapacity.guests) {
          if (guest.guestOrgDescendantIds?.includes(personaFiltersOrgId)) {
            // selected location is a descendant of the guest, so we list selected location descendants
            ancestorOrgIds.push(personaFiltersOrgId)
          } else if (personaService.getOrgAncestorIds(guest.guestOrgId).includes(personaFiltersOrgId)) {
            // selected location is an ancestor of the guest, so we list guest descendants
            ancestorOrgIds.push(guest.guestOrgId)
          }
        }
        guestOrgFilter = buildAncestorOrgIdsFilter(ancestorOrgIds)
      }
    } else {
      if (!preselectedCapacity) selectedStudentsSchool = selectedOrg
      else if (selectedCapacity.guests.some(cg => !cg.guestOrgId)) showStudentSchoolPicker = true
      else guestOrgFilter = buildAncestorOrgIdsFilter(selectedCapacity.guests.map(cg => cg.guestOrgId))
    }
  }

  async function onSelectedStudentsSchoolChanged() {
    if (inputGuestOrgId === lastGuestOrgId) return
    lastGuestOrgId = inputGuestOrgId
    if (selectedStudentsSchool != null) {
      await setPreceptorIdsDefault()
      allPreceptors ??= await api.staff.list(
        {
          orgId: personaTypeIsSchool ? hostOrgId : $persona.orgId,
          filters: [
            {
              type: FilterType.StaffRoleOrg,
              config: { staffRoles: [StaffRole.Preceptor], orgId: hostOrgId },
            },
          ],
        },
        api.noMonitor
      )
      allPreceptors = allPreceptors.staff?.map(s => s.user)
    }
    await resetInputToCheckpoint('school', { willBeInitial: false })
  }

  function getCapacityGuest(selectedCapacity, selectedStudentsSchool) {
    if (!selectedCapacity || !selectedStudentsSchool) return null

    // Look for exact match, then ancestor match, then Open (any school) guest
    const guests = selectedCapacity.guests
      .map(cg => ({
        guest: cg,
        isExactMatch: cg.guestOrgId === selectedStudentsSchool.orgId,
        isAncestorMatch: selectedStudentsSchool.ancestorOrgIds?.includes(cg.guestOrgId),
        isOpenAnySchool: cg.guestOrgId == null,
      }))
      .filter(cg => cg.isExactMatch || cg.isAncestorMatch || cg.isOpenAnySchool)

    return guests.length ? _.orderBy(guests, ['isExactMatch', 'isAncestorMatch', 'isOpenAnySchool'], ['desc', 'desc', 'desc'])[0].guest : null
  }

  function setGuestScheduleNotStartedMessage() {
    guestScheduleNotStartedMessage = null
    if (!capacityGuest) return
    const currentDate = dayjs()
    const guestScheduleStart = capacityGuest.schedulingStartDate ? dayjs(capacityGuest.schedulingStartDate) : null
    const guestScheduleEnd = capacityGuest.schedulingEndDate ? dayjs(capacityGuest.schedulingEndDate) : null
    if (!guestScheduleStart && !guestScheduleEnd) return
    if (guestScheduleStart && currentDate.isBefore(guestScheduleStart, 'day')) {
      guestScheduleNotStartedMessage =
        'Scheduling hasn’t started yet for the selected school; please select a different location or another opportunity.'
    } else if (guestScheduleEnd && currentDate.isAfter(guestScheduleEnd, 'day')) {
      guestScheduleNotStartedMessage =
        'Scheduling has already ended for the selected school; please select a different location or another opportunity.'
    }
  }

  async function setPreceptorIdsDefault() {
    await tick() // input.studentIds gets updated next tick
    if (!inputCapacityId || !$studentCount) return
    const selectedPreceptorIds = input.preceptorIds

    autoAssignedPreceptorIds = await api.capacity.getDefaultPreceptorIds({ capacityId: inputCapacityId, studentIds: inputStudentIds }, api.noMonitor)
    autoAssignedPreceptors = allPreceptors?.filter(p => autoAssignedPreceptorIds.includes(p.userId) && !selectedPreceptorIds?.includes(p.userId))
  }

  function onPreceptorsChanged() {
    const selectedPreceptorIds = input.preceptorIds
    //If they explicity add a preceptor, remove them from the auto assigned list and vice versa
    if (selectedPreceptorIds?.some(sp => autoAssignedPreceptorIds?.includes(sp))) {
      autoAssignedPreceptors = autoAssignedPreceptors?.filter(p => !selectedPreceptorIds?.includes(p.userId))
    }

    if (autoAssignedPreceptorIds?.some(aap => !selectedPreceptorIds?.includes(aap))) {
      autoAssignedPreceptors = allPreceptors?.filter(p => autoAssignedPreceptorIds?.includes(p.userId) && !selectedPreceptorIds?.includes(p.userId))
    }
    getAssignedPreceptors(selectedPreceptors, autoAssignedPreceptors)
  }

  function getAssignedPreceptors(selectedPreceptors, autoAssignedPreceptors) {
    assignedPreceptors = allPreceptors?.filter(
      p => selectedPreceptors?.some(sp => sp.userId === p.userId) || autoAssignedPreceptors?.some(aap => aap.userId === p.userId)
    )
  }

  function onCourseGroupChanged(group) {
    // user can easily clear if they don't want all students. we ask for course before we ask for students,
    // so feels pretty natural to just override any student selection the might've made already.
    input.studentIds = selectedCourse?.students?.filter(s => s.courseStudentGroupId == group.courseStudentGroupId).map(s => s.userId) ?? []
  }

  async function onCourseChanged() {
    if (!inputCourseId) return
    await tick() // not sure why, but if don't, buttonText in student picker doesn't update
    input.studentIds = []
  }

  function disableStudentlessOrgs(org) {
    return { disabled: org.studentCount === 0 }
  }

  function onRemoveStaff(userId) {
    input.preceptorIds = input.preceptorIds.filter(id => id !== userId)
  }

  function onRemoveStudent(userId) {
    input.studentIds = input.studentIds.filter(id => id !== userId)
  }

  function onRemoveFaculty(userId) {
    input.schoolFacultyIds = input.schoolFacultyIds.filter(id => id !== userId)
  }
</script>
