{#each capacity.studentYears as studentYear}
  <div class="flex-row flex-align-center g05">
    <Icon name="calendars" class="color-text-teal" />
    <span>{toStudentYear(studentYear)} students</span>
  </div>
{:else}
  <div class="flex-row flex-align-center g05">
    <Icon name="calendars" class="color-text-teal" />
    <em class="small text-gray">Any year student</em>
  </div>
{/each}

<script>
  import { toStudentYear } from 'services/formatters.js'
  import Icon from 'components/Icon.svelte'

  export let capacity
</script>
