{#if show}
  <div class="flex-row flex-align-center flex-wrap g05">
    {#if postToSearchPage}
      <span>Available on the</span>
    {:else}
      <strong class="strongish">NOT</strong>
      <span>available on the</span>
    {/if}
    <span class="nowrap">
      <Icon name="search" />
      <strong class="strongish">Find clinical experiences</strong>
    </span>
    <span>page</span>
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'

  export let capacity
  export let show = true

  $: postToSearchPage = capacity.postToSearchPage
</script>
