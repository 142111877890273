<div class="hide">
  <div bind:this={tooltipElem} class="flex-column g05 text-left">
    <p class="m0">A request is counted as <em>accepted</em> if the rotation is in any of these statuses:</p>
    <ul class="m0 pl2 list-none">
      {#each Object.values(MatchStatus).filter(s => s >= MatchStatus.Onboarding && s < MatchStatus.Closed) as status}
        <li><MatchStatusLabel {status} /></li>
      {/each}
    </ul>
    <p class="m0">
      Rotations which have been <MatchStatusLabel status={MatchStatus.Closed} /> don’t count toward <em>accepted requests</em>, even if they did
      previously.
    </p>
  </div>
</div>

<span class="has-tooltip" use:tip={tooltipElem ? { content: tooltipElem, options: { theme: 'light-gray-scrollable', maxWidth: 250 } } : null}
  >accepted requests</span
>

<script>
  import { MatchStatus } from 'config/enums.js'
  import MatchStatusLabel from 'components/MatchStatusLabel.svelte'
  import tip from 'decorators/tip.js'

  let tooltipElem = null
</script>
